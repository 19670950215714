import React, { PureComponent } from "react";
import {
  Linkedin,
  Twitter,
  Download,
  Link,
  Facebook,
  AlertTriangle,
} from "react-feather";
import FilePdfIcon from "mdi-react/FilePdfIcon";
import WhatsappIcon from "mdi-react/WhatsappIcon";
import axios from "axios";
import QRCode from "qrcode.react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { isMobile } from "react-device-detect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from "react-tooltip";
import { server, front, cert, share, client, logo } from "../../env";

export default class Certificate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      template_img: "",
      qr_front_color: "#000000",
      qr_back_color: "#ffffff",
      certificate_id: this.props.match.params.credential_id,
      text_xx: 0,
      text_yy: 0,
      textAlign: "center",
      is_open: false,
      extraFields: [],
      extraFieldsData: [],
    };
  }
  componentDidMount() {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });

    // var element = new Image;
    // var devtoolsOpen = false;
    // element.__defineGetter__("id", function() {
    //     devtoolsOpen = true;
    // });
    // setInterval(()=> {
    //     devtoolsOpen = false;
    //     console.log(element);
    //     this.setState({is_open: devtoolsOpen ? true:false})
    // }, 1000);
    this.getData();
  }
  printDocument = () => {
    const el = document.getElementById("page");
    el.style.display = "inline-block";

    html2canvas(el, {
      useCORS: true,
      scrollX: 0,
      scrollY: -window.scrollY,
      loggin: true,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById("page").style.opacity = 1;
      },
    }).then(function (canvas) {
      var data = canvas.toDataURL("image/jpeg");
      // document.getElementById('screenshot').src = data;
      const pdf = new jsPDF("l", "px", "letter");
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(data, "JPEG", 3, 5, width - 10, height - 10);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
      el.style.display = "none";
    });
  };
  DownloadasImage = () => {
    const el = document.getElementById("page");
    el.style.display = "inline-block";

    html2canvas(el, {
      useCORS: true,
      scrollX: 0,
      scrollY: -window.scrollY,
      loggin: true,
      onclone: function (clonedDoc) {
        clonedDoc.getElementById("page").style.opacity = 1;
      },
    }).then(function (canvas) {
      var image = canvas
        .toDataURL("image/jpeg")
        .replace("image/png", "image/octet-stream");
      var link = document.createElement("a");
      link.download = "certificate.jpeg";
      link.href = image;
      link.click();
      el.style.display = "none";
    });
  };

  getData = () => {
    axios
      .get(server + "/credential/details/" + this.state.certificate_id)
      .then((rsp) => {
        console.log(rsp);
        if (!rsp.data.is_published) {
          window.location.href = front + "/certificate-not-found";
        } else {
          if (!rsp.data.event_id.publish) {
            window.location.href = front + "/certificate-not-found";
          } else {
            this.setState({ loaded: true });
          }
        }
        this.setState({
          name: rsp.data.name,
          issuer_name: rsp.data.event_id.event_created_by.org_name,
          issuer_is_verified: rsp.data.event_id.event_created_by.is_verified,
          issuer_website: rsp.data.event_id.website,
          event_name: rsp.data.event_id.public_name,
          event_description:
            rsp.data.event_id.description === ""
              ? null
              : rsp.data.event_id.description,
          template_updated_on:
            rsp.data.event_id.event_template.template_updated_on,
          certificate_template_updated_on: rsp.data.template_updated_on,
          issue_date: rsp.data.issue_date,

          template_img:
            rsp.data.event_id.event_template.template_img.template_img,
          text_width: rsp.data.event_id.event_template.text_width,
          text_height: rsp.data.event_id.event_template.text_height,
          text_x: rsp.data.event_id.event_template.text_x_cord,
          text_y: rsp.data.event_id.event_template.text_y_cord,
          font_face: rsp.data.event_id.event_template.font_face,
          font_text: rsp.data.event_id.event_template.font_name,
          font_size: rsp.data.event_id.event_template.font_size,
          color: rsp.data.event_id.event_template.font_color,
          textAlign: rsp.data.event_id.event_template.font_align,
          qr_width: rsp.data.event_id.event_template.qr_width,
          qr_height: rsp.data.event_id.event_template.qr_height,
          qr_x: rsp.data.event_id.event_template.qr_x_cord,
          qr_y: rsp.data.event_id.event_template.qr_y_cord,
          qr_front_color: rsp.data.event_id.event_template.qr_front_color,
          qr_back_color: rsp.data.event_id.event_template.qr_back_color,
          offsetx: rsp.data.event_id.event_template.text_x_cord_percent,
          offsety: rsp.data.event_id.event_template.text_y_cord_percent,
          extraFields: JSON.parse(rsp.data.event_id.event_template.extraFields),
          extraFieldsData: rsp.data.extraFields.split(","),
          // window_width:document.body.clientWidth,
          window_width: 2400,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          window.location.href = front + "/certificate-not-found";
        }
      });
  };
  test = () => {
    var inner_div = document.getElementById("inner_div");
    var text = document.getElementById("d_text");
    var tot = inner_div.offsetLeft + text.offsetLeft;
    var tot2 = inner_div.offsetTop + 20;
    this.setState({ text_xx: tot, text_yy: tot2 });
  };
  notify = (msg, toast_type) => {
    toast(msg, {
      type: toast_type,
    });
  };
  copy = () => {
    this.notify("Link has been copied.", "success");
    this.social_media_share();
  };

  social_media_share = (social_handle) => {
    const {
      certificate_id,
      certificate_template_updated_on,
      template_updated_on,
    } = this.state;
    if (template_updated_on === certificate_template_updated_on) {
      if (social_handle === "facebook") {
        window.open(
          "https://www.facebook.com/sharer/sharer.php?u=" +
            share +
            "/certificate/" +
            certificate_id,
          "_blank"
        );
      } else if (social_handle === "linkedin") {
        window.open(
          "https://www.linkedin.com/shareArticle?mini=true&url=" +
            share +
            "/certificate/" +
            certificate_id,
          "_blank"
        );
      } else if (social_handle === "twitter") {
        window.open(
          "http://twitter.com/share?text=Certificate:&url=" +
            share +
            "/certificate/" +
            certificate_id +
            "&hashtags=certifidoc,success,achievement",
          "_blank"
        );
      } else if (social_handle === "whatsapp") {
        window.open(
          "whatsapp://send?text=" + share + "/certificate/" + certificate_id,
          "_blank"
        );
      } else if (social_handle === "linkedin_certificate") {
        window.open(
          "http://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=" +
            this.state.event_name +
            "&issueMonth=" +
            this.state.issue_date.split("-")[1] +
            "&issueYear=20" +
            this.state.issue_date.split("-")[2] +
            "&certUrl=" +
            share +
            "/certificate/" +
            certificate_id +
            "&certId=" +
            this.state.certificate_id,
          "_blank"
        );
      }
    } else {
      const el = document.getElementById("page");
      el.style.display = "inline-block";
      html2canvas(el, {
        useCORS: true,
        scrollX: 0,
        scrollY: -window.scrollY,
        loggin: true,
        onclone: function (clonedDoc) {
          clonedDoc.getElementById("page").style.opacity = 1;
        },
      }).then(function (canvas) {
        var data = canvas.toDataURL("image/jpeg");
        el.style.display = "none";

        const paramsss = new URLSearchParams();
        paramsss.append("cred_img", data);
        paramsss.append("template_updated_on", template_updated_on);
        axios
          .post(server + "/meta/base64/" + certificate_id, paramsss)
          .then((rsp) => {
            if (social_handle === "facebook") {
              window.open(
                "https://www.facebook.com/sharer/sharer.php?u=" +
                  share +
                  "/certificate/" +
                  certificate_id,
                "_blank"
              );
            } else if (social_handle === "linkedin") {
              window.open(
                "https://www.linkedin.com/shareArticle?mini=true&url=" +
                  share +
                  "/certificate/" +
                  certificate_id,
                "_blank"
              );
            } else if (social_handle === "twitter") {
              window.open(
                "http://twitter.com/share?text=Certificate: &url=" +
                  share +
                  "/certificate/" +
                  certificate_id +
                  "&hashtags=certifidoc,success,achievement",
                "_blank"
              );
            } else if (social_handle === "whatsapp") {
              window.open(
                "whatsapp://send?text=" +
                  share +
                  "/certificate/" +
                  certificate_id,
                "_blank"
              );
            } else if (social_handle === "linkedin_certificate") {
              window.open(
                "http://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=" +
                  this.state.event_name +
                  "&issueMonth=" +
                  this.state.issue_date.split("-")[1] +
                  "&issueYear=20" +
                  this.state.issue_date.split("-")[2] +
                  "&certUrl=" +
                  share +
                  "/certificate/" +
                  certificate_id +
                  "&certId=" +
                  this.state.certificate_id,
                "_blank"
              );
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
            }
          });
      });
    }
  };
  render() {
    const { extraFields, extraFieldsData } = this.state;
    const text_style = {
      position: "absolute",
      display: "inline-block",
      left: (this.state.text_x / this.state.offsetx) * 100 + "%",
      top: (this.state.text_y / this.state.offsety) * 100 + "%",
      width: (this.state.text_width / this.state.offsetx) * 100 + "%",
      height: (this.state.text_height / this.state.offsety) * 100 + "%",
      justifyContent: this.state.textAlign,
    };
    const qr_style = {
      left: (this.state.qr_x / this.state.offsetx) * 100 + "%",
      top: (this.state.qr_y / this.state.offsety) * 100 + "%",
      position: "absolute",
      display: "inline-block",
      width: (this.state.qr_width / this.state.offsetx) * 100 + "%",
      height: "auto",
    };
    const font = {
      color: this.state.color,
      fontFamily: this.state.font_face,
      fontSize: isMobile
        ? (this.state.font_size / this.state.window_width) * 100 + 2.5 + "vw"
        : (this.state.font_size / this.state.window_width) * 100 + "vw",
    };

    const temp_font = {
      color: this.state.color,
      fontFamily: this.state.font_face,
      fontSize:
        (this.state.font_size / this.state.window_width) * 100 + 2 + "vw",
    };
    return this.state.is_open ? (
      <div className="jumbotron d-flex align-items-center min-vh-100">
        <div className="container text-center alert-icon">
          <AlertTriangle />
          <br></br>
          Please close dev console to preview your certificate.
        </div>
      </div>
    ) : this.state.loaded ? (
      <main className="container">
        <Helmet>
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={client + "/certificate/" + this.state.certificate_id}
          />
          <meta
            property="og:title"
            content={
              this.state.name +
              "'s Certificate for " +
              this.state.event_name +
              " by " +
              this.state.issuer_name
            }
          />
          <meta property="og:description" content="Custom description" />
          <meta
            property="og:image"
            name="image"
            content="http://wilyer.com/betasignage.wilyer.com/s.php"
          />

          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={client + "/certificate/" + this.state.certificate_id}
          />
          <meta
            property="twitter:title"
            content={
              this.state.name +
              "'s Certificate for " +
              this.state.event_name +
              " by " +
              this.state.issuer_name
            }
          />
          <meta property="twitter:description" content="custom description" />
          <meta
            property="twitter:image"
            content="http://wilyer.com/betasignage.wilyer.com/s.php"
          />
        </Helmet>
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="d-flex row px-3 align-items-center justify-content-between">
              <div className="col-6">
                <a className="logo" href="index.html">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/img/logo/${logo}`}
                    height="70"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div
            className="col-lg-6 col-12 shadow-lg bg-white offset-md-3"
            style={{ display: "inline-block", position: "relative" }}
          >
            <img
              id="img"
              src={this.state.template_img}
              alt="addsuffix"
              className="img-fluid"
              style={{ position: "relative" }}
              draggable="false"
              ref={(templateElement) => {
                this.templateElement = templateElement;
              }}
            />
            <div
              id="inner_div"
              style={text_style}
              className="d-flex align-items-center"
            >
              <div
                id="d_text"
                style={font}
                ref={(textElement) => {
                  this.textElement = textElement;
                }}
              >
                {" "}
                {this.state.name}{" "}
              </div>
            </div>

            {/* v1.3 Extra Fields */}
            {extraFields.map((ef, index) => (
              <div
                id="inner_div"
                style={{
                  position: "absolute",
                  display: "inline-block",
                  left: (ef.x / this.state.offsetx) * 100 + "%",
                  top: (ef.y / this.state.offsety) * 100 + "%",
                  width: (ef.w / this.state.offsetx) * 100 + "%",
                  height: (ef.h / this.state.offsety) * 100 + "%",
                  justifyContent: ef.textAlign,
                }}
                className="d-flex align-items-center"
              >
                <div
                  id="d_text"
                  style={{
                    color: ef.fontColor,
                    fontFamily: ef.fontFamily,
                    fontSize: isMobile
                      ? (ef.fontSize / this.state.window_width) * 100 +
                        2.5 +
                        "vw"
                      : (ef.fontSize / this.state.window_width) * 100 + "vw",
                  }}
                  ref={(textElement) => {
                    this.textElement = textElement;
                  }}
                >
                  {extraFieldsData[index]}
                </div>
              </div>
            ))}

            <div style={qr_style}>
              <div
                style={{
                  backgroundColor: [this.state.qr_back_color],
                  padding: "3px 3px 3px 3px",
                }}
              >
                <QRCode
                  className="qr_code_svg"
                  id="123456"
                  value={cert + "certificate/" + this.state.certificate_id}
                  size="100%"
                  renderAs="svg"
                  bgColor={this.state.qr_back_color}
                  fgColor={this.state.qr_front_color}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-10 offset-md-1">
            <div className="d-flex row px-3 g-4 justify-content-between">
              <div className="col-12 text-center">
                <ul className="mb-0 p-0">
                  <li className="list-inline-item mx-2 mt-3">
                    <a
                      className="btn btn-pills btn-blue"
                      data-tip="Add to Linked in Certificate"
                      onClick={this.social_media_share.bind(
                        this,
                        "linkedin_certificate"
                      )}
                    >
                      + Linkedin Certificates
                    </a>
                    {/* <img src="../../assets/img/addto.png"/> */}
                  </li>
                  <li className="list-inline-item mx-2 mt-3">
                    <a
                      href="javascript:void(0)"
                      onClick={this.social_media_share.bind(this, "facebook")}
                      className="btn btn-icon btn-pills btn-lg btn-blue"
                      data-tip="Facebook"
                    >
                      <Facebook />
                    </a>
                  </li>

                  <li className="list-inline-item mx-2 mt-3">
                    <a
                      href="javascript:void(0)"
                      onClick={this.social_media_share.bind(this, "linkedin")}
                      className="btn btn-icon btn-pills btn-lg btn-secondary"
                      data-tip="Linkedin"
                    >
                      <Linkedin />
                    </a>
                  </li>
                  <li className="list-inline-item mx-2 mt-3">
                    <a
                      href="javascript:void(0)"
                      onClick={this.social_media_share.bind(this, "twitter")}
                      className="btn btn-icon btn-pills btn-lg btn-info"
                      data-tip="Twitter"
                    >
                      <Twitter />
                    </a>
                  </li>
                  <li className="list-inline-item mx-2 mt-3">
                    <a
                      href="javascript:void(0)"
                      onClick={this.social_media_share.bind(this, "whatsapp")}
                      className="btn btn-icon btn-pills btn-lg btn-green"
                      data-tip="Whatsapp"
                    >
                      <WhatsappIcon />
                      {/* <ClassicSpinner size={25}/> */}
                    </a>
                  </li>
                  <li className="list-inline-item mx-2 mt-3">
                    <CopyToClipboard
                      onCopy={this.copy}
                      text={share + "/certificate/" + this.state.certificate_id}
                    >
                      <a
                        href="javascript:void(0)"
                        className="btn btn-icon btn-pills btn-lg btn-danger"
                        data-tip="Copy Link"
                      >
                        <Link />
                      </a>
                    </CopyToClipboard>
                  </li>
                  <li className="list-inline-item mx-2 mt-3">
                    <a
                      href="javascript:void(0)"
                      className="btn btn-icon btn-pills btn-lg btn-warning"
                      onClick={this.printDocument}
                      data-tip="Download as PDF"
                    >
                      <FilePdfIcon color="#fff" />
                    </a>
                  </li>
                  <li className="list-inline-item mx-2 mt-3">
                    <a
                      href="javascript:void(0)"
                      className="btn btn-icon btn-pills btn-lg btn-danger"
                      onClick={this.DownloadasImage}
                      data-tip="Download as Image"
                    >
                      <Download color="#fff" />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-12">
                <div className="section-title">
                  <h3 className="title">{this.state.event_name}</h3>
                  {this.state.event_description ? (
                    <p className="text-muted para-desc mb-0">
                      <span className="text-primary font-weight-bold">
                        Description :{" "}
                      </span>{" "}
                      {this.state.event_description}
                    </p>
                  ) : (
                    <p className="text-muted para-desc mb-0">
                      <span className="text-primary font-weight-bold">
                        Description :{" "}
                      </span>
                    </p>
                  )}
                </div>
              </div>
              <div className="col-6 my-5">
                <div
                  className="card public-profile border-0 rounded shadow h-100"
                  style={{ zIndex: "1" }}
                >
                  <div className="card-body pb-0">
                    <div className="row align-items-center">
                      <div className="col-lg-3 col-md-3 text-md-left text-center">
                        <img
                          src="/assets/img/user.svg"
                          className="mx-auto"
                          alt=""
                          width="100%"
                        />
                      </div>
                      <div className="col-lg-8 col-md-9">
                        <small className="text-muted h6 mr-2">
                          Certificate Recipient
                        </small>
                        <h5 className="title h5 mb-0">{this.state.name}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 my-5">
                <div
                  className="card public-profile border-0 rounded shadow h-100"
                  style={{ zIndex: "1" }}
                >
                  <div className="card-body pb-0">
                    <div className="row align-items-center">
                      <div className="col-lg-3 col-md-3 text-md-left text-center">
                        <img
                          src="/assets/img/organization.png"
                          className="mx-auto"
                          alt=""
                          width="100%"
                        />
                      </div>
                      <div className="col-lg-9 col-md-9">
                        {this.state.issuer_is_verified ? (
                          <small className="text-muted h6 mr-2">
                            Issued By{" "}
                            <span className="d-xs-block">
                              <img
                                src="/assets/img/verified.svg"
                                height="10"
                                width="10"
                                className="mb-1 mt-1"
                                alt=""
                              />{" "}
                              <small className="text-success">Verified</small>
                            </span>
                          </small>
                        ) : (
                          <small className="text-muted h6 mr-2">
                            Issued By{" "}
                            <span className="d-xs-block">
                              <img
                                src="/assets/img/warning.svg"
                                height="10"
                                width="10"
                                className="mb-1 mt-1"
                                alt=""
                              />{" "}
                              <small className="text-danger">
                                Not Verified
                              </small>
                            </span>
                          </small>
                        )}

                        <h5 className="title h5 mb-0">
                          {this.state.issuer_website ? (
                            <a
                              href={this.state.issuer_website}
                              className="link"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {this.state.issuer_name}
                            </a>
                          ) : (
                            this.state.issuer_name
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Temp Certificate */}
        <div
          className="col-12 shadow-lg bg-white"
          id="page"
          style={{ display: "none", opacity: 0, position: "relative" }}
        >
          <img
            id="img"
            crossOrigin="anonymous"
            src={this.state.template_img + "?x-request=xhr"}
            alt="addsuffix"
            className="img-fluid back-img"
            style={{ position: "relative" }}
            draggable="false"
            ref={(templateElement) => {
              this.templateElement = templateElement;
            }}
          />
          <div
            id="inner_div"
            style={text_style}
            className="d-flex align-items-center"
          >
            <span
              id="d_text"
              style={temp_font}
              ref={(textElement) => {
                this.textElement = textElement;
              }}
            >
              {" "}
              {this.state.name}
            </span>
          </div>
          {/* v1.3 Extra Fields */}
          {extraFields.map((ef, index) => (
            <div
              id="inner_div"
              style={{
                position: "absolute",
                display: "inline-block",
                left: (ef.x / this.state.offsetx) * 100 + "%",
                top: (ef.y / this.state.offsety) * 100 + "%",
                width: (ef.w / this.state.offsetx) * 100 + "%",
                height: (ef.h / this.state.offsety) * 100 + "%",
                justifyContent: ef.textAlign,
              }}
              className="d-flex align-items-center"
            >
              <div
                id="d_text"
                style={{
                  color: ef.fontColor,
                  fontFamily: ef.fontFamily,
                  fontSize: isMobile
                    ? (ef.fontSize / this.state.window_width) * 100 + 30.8 + "vw"
                    : (ef.fontSize / this.state.window_width) * 100 + 0.6+ "vw",
                }}
                ref={(textElement) => {
                  this.textElement = textElement;
                }}
              >
                {extraFieldsData[index]}
              </div>
            </div>
          ))}
          <div style={qr_style}>
            <div
              style={{
                backgroundColor: [this.state.qr_back_color],
                padding: "3px 3px 3px 3px",
              }}
            >
              <QRCode
                className="qr_code_svg"
                id="123456"
                value={cert + "certificate/" + this.state.certificate_id}
                bgColor={this.state.qr_back_color}
                fgColor={this.state.qr_front_color}
              />
            </div>
          </div>
        </div>
        <ToastContainer />
        <ReactTooltip />
      </main>
    ) : (
      <main className="container">
        <div className="se-pre-con"></div>
      </main>
    );
  }
}
