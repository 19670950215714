import React, { PureComponent } from "react";
import Server from "../../../routes/server";

export default class Help extends PureComponent {
  componentDidMount(){
    Server.Session_check()
  }
  render() {
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4 shadow-sm  pl-3">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Help</h3>
          </div>
        </div>

        

        <div className="row my-5">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <div className="header mt-md-5 text-center">
                  <div className="header-body">
                    <h5 className="header-title">Certifidoc User Guide </h5>
                    <p>
                      {" "}
                      Follow the given steps to learn how Certifidoc can be
                      used.{" "}
                    </p>
                  </div>
                </div>

                <div className="header mt-md-5 text-center">
                  <div className="header-body">
                    <h5 className="header-title">
                      Basic Certifidoc Terminologies
                    </h5>
                  </div>
                </div>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">What is an Event?</h5>
                  </div>
                </div>
                <p>
                  {" "}
                  An Event is anything for which you need to make certificate
                  and publish.{" "}
                </p>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      What is Credential/Certificate?
                    </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  Credential/Certificate is a single and unique setup for each
                  recipient in the event.{" "}
                </p>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">Who is Recipient?</h5>
                  </div>
                </div>
                <p>
                  {" "}
                  Recipient is anyone who will be provided certificates for
                  performance, participation and completion.{" "}
                </p>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">What is Unpublished Event?</h5>
                  </div>
                </div>
                <p>
                  {" "}
                  Unpublished Event is a state of event where the Event's
                  credentials/certificates will not be visible to public.{" "}
                </p>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">What is Published Event?</h5>
                  </div>
                </div>
                <p>
                  {" "}
                  Published Event is a state of event where the Event's
                  credentials/certificates will be visible to public.{" "}
                </p>

                <div className="header mt-md-5 text-center">
                  <div className="header-body">
                    <h5 className="header-title">Getting Started Guide</h5>
                  </div>
                </div>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      {" "}
                      STEP 1 : Know your User Dashboard{" "}
                    </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  User dashboard gives all the information about the published &
                  unpublished events and credentials, so that users can have a
                  count on their events.
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title"> Step 2: CREATE NEW EVENT </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  Navigate to NEW EVENT tab present. In the New event tab users
                  need to fill in some details about their event .{" "}
                </p>
                <ul>
                  <li>
                    Display Name: Enter the name of the event publisher that
                    will be published publicly and will be visible on the
                    certificate verification and emails.
                  </li>
                  <li>
                    Event Name: Enter the name of the event , it will only be
                    visible on your dashboard for distinguishing your
                    certificates.
                  </li>
                  <li>
                    Certificate type: Users need to select the type of
                    certificate they want to publish.If they don't find the
                    desired certificate type they can choose “other” option and
                    can manually type in .
                  </li>
                  <li>
                    Event date: Select the starting and ending date of the event
                    .
                  </li>
                  <li>
                    Event description: Give a description for the event
                    organised.This event description will be visible publicly on
                    the certificate verification section.
                  </li>
                  <li>
                    Company Website: This is an optional Field if you have a
                    website that you want users to get redirected to when
                    visitors see certificates published by you.
                  </li>
                </ul>
                <p>
                  Select “PROCEED” to process the details and continue to the
                  next step. You can also save the details as a draft using
                  “SAVE DRAFT” and can continue later.
                </p>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      {" "}
                      STEP 3 : CHOOSE A TEMPLATE{" "}
                    </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  Select the “CREATE NEW DESIGN” option and UPLOAD the template
                  of certificate from your device in the required format and
                  style as shown in the image.{" "}
                </p>
                <p>
                  NOTE: Upload the template in the right format (leave space for
                  recipient name and space for qr code) and the template should
                  be in image format (jpg,png,jpeg)
                </p>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      {" "}
                      STEP 4 : CERTIFICATE EDITOR{" "}
                    </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  Users can make changes and edit the certificate’s text and qr
                  code in this section.{" "}
                </p>
                <p>
                  {" "}
                  Place the text box and qr code at the desired area by simply
                  clicking and dragging them.
                </p>

                <p>You can use the following formatting tools available:</p>
                <p>DISPLAY TEXT TOOLS</p>
                <ul>
                  <li>
                    Font Style: Select the text font style from the list of
                    fonts available.
                  </li>
                  <li>
                    Font Size: Adjust the size of the text by scrolling the font
                    size scale.
                  </li>
                  <li>
                    Font color: Choose the text color using the color palette
                    present.
                  </li>
                  <li>
                    Bring to center: This option brings the text box in the
                    center of the certificate.
                  </li>
                  <li>
                    Center align lock: This option locks the text box to align
                    in the centre line of the certificate. You can
                    enable/disable it by clicking once.
                  </li>
                  <li>
                    Test Long Name: You can use this tool for visualising how
                    the recipient name will look on the final certificate.
                  </li>
                </ul>

                <p>QR CODE TOOLS</p>
                <ul>
                  <li>
                    Bring to center: This option brings the QR Code in the
                    center of the certificate.
                  </li>
                  <li>
                    Center align lock: This option locks the QR Code to align in
                    the centre line of the certificate. You can enable/disable
                    it by clicking once.
                  </li>
                  <li>
                    Font color: Choose the QR Code color using the color palette
                    present.
                  </li>
                  <li>
                    Background color: Choose the QR Code background color using
                    the color palette present.
                  </li>
                </ul>

                <p>
                  Use “SAVE TEMPLATE” to save your design and visit “Go to
                  events” to add credentials of the recipients after you are
                  done formatting.
                </p>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title"> STEP 5 : ADD CREDENTIALS </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  In the OVERVIEW section you can add credentials, update
                  credentials , publish/unpublish certificates.{" "}
                </p>
                <p> TO ADD CREDENTIALS </p>
                <ul>
                  <li>Select “ADD NEW CREDENTIALS” option.</li>
                  <li>
                    You can either upload a file containing data of the
                    recipients using the “CHOOSE FILE” option or you can enter
                    details of each recipient manually by selecting the “ADD
                    NEW” option.
                  </li>
                </ul>

                <p>CHOOSE FILE OPTION</p>
                <ul>
                  <li>
                    Upload the file containing the details of the recipient .
                  </li>
                  <p>
                    Note- a sample excel file is available for download user can
                    refer the excel file for uploading the file
                  </p>
                  <li>
                    The file needs to follow the same layout as given in the
                    Sample excel file i.e name,email,mobile no.,issue date and
                    expiry date.
                  </li>
                </ul>

                <p>ADD NEW OPTION</p>
                <ul>
                  <li>Select add new option.</li>

                  <li>
                    Add name, email id, contact number, issue date and expiry
                    date of the certificate.
                  </li>
                  <li>
                    After adding credentials you can either save and publish
                    right away or just save it and publish it later on.
                  </li>
                  <p>
                    NOTE:- all the recipients added and published will receive
                    an email having there certificate url from where they can
                    download and share to different platforms.
                  </p>
                </ul>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      {" "}
                      STEP 6 : MAKE CERTIFICATES PUBLIC{" "}
                    </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  After adding credentials to can make the certificates public
                  for future verification and access to certificate recipients.{" "}
                </p>
                <ul>
                  <li>
                    Navigate at the top of the event name you will find a public
                    button, turn it ON to make the certificate public.
                  </li>
                  <li>You can change the settings later on.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
