import React, { PureComponent } from "react";




export default class Not_Found extends PureComponent {

  render() {
    return (
    <main className="container ">
        Page not found
      </main>
    );
  }
}