import React, { PureComponent } from "react";
import { Route, Switch } from "react-router-dom";

import login from "../source/auth/login";
import signup from "../source/auth/signup";
import forgot from "../source/auth/forgot";
import Master from "../source/auth/master";


export default class Auth_Router extends PureComponent {
  render() {
    return (
      <Switch>
        <Route path="/forgot" component={forgot} />
        <Route path="/signup" component={signup} />
        <Route path="/master/:token" component={Master} />
        <Route path="/" component={login} />
      </Switch>
    );
  }
}
