import React, { PureComponent } from 'react'

export default class Master extends PureComponent {
    componentDidMount(){
        localStorage.removeItem("footprint");
        localStorage.removeItem("timestamp");
        localStorage.setItem("footprint", this.props.match.params.token);
        localStorage.setItem("timestamp", new Date());
        window.location.href = "/";
    }
    render() {
        return (
            <div>
                
            </div>
        )
    }
}
