import React, { PureComponent } from "react";
import Server from "../../../routes/server";

export default class Settings extends PureComponent {
  componentDidMount(){
    Server.Session_check()
  }
  render() {
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4 shadow-sm  pl-3">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Certifidoc Updates</h3>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-8">
            <div className="card p-4 pt-5">
              <h5 className="d-inline-block">
                <span className="badge bg-info mt-n1 rounded-pill">v1.1</span>
              </h5>
              <span className="text-muted ml-2">
                – March 7th, 2021
              </span>
              <ul>
                <li>Minor bug fixes</li>
                <li>Major UI & UX Improvements</li>
                <li>Added controls for recipient in editor</li>
                <li>Organization verification added</li>
              </ul>
            </div>
          </div>
          <div className="col-md-8">
            <div className="card p-4 pt-5">
              <h5 className="d-inline-block">
                <span className="badge bg-info mt-n1 rounded-pill">v1.0</span>
              </h5>
              <span className="text-muted ml-2">
                – October 3rd, 2020
              </span>
              <ul>
                <li>
                  Initial release of CertifiDoc! Lots more coming soon though{" "}
                  <span role="img" aria-labelledby="panda1">
                    😁
                  </span>
                </li>
                <li>Event Add</li>
                <li>Create Certificates</li>
                <li>Social Media Link Sharing and Certificate Preview</li>
                <li>Email Recipients with Certificate Links</li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
