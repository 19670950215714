const hostname = window.location.href;

// Dynamic paths
let server, client, share, cert, front;
let logo = "certifidoc_logo.png";
const SITE_KEY = "6Lf92dIZAAAAAGkN-KKcyJsgxE_3nK67qJ3w6ncq";
const canva_api_key = "y9sGLX_Iocg8ByWszXgFzPbS";
const environment = "PROD";
if (hostname.includes("localhost")) {
  server = share = "http://localhost:8000";
  client = front = "http://localhost:3000";
  cert = "http://localhost:3000/";
} else if (hostname.includes("littlecuberssolution")) {
  logo = "littlecuberssolution.png";
  server = "https://cert-api.littlecuberssolution.com";
  client = "https://cert-dashboard.littlecuberssolution.com";
  share = "https://cert.littlecuberssolution.com";
  cert = "https://cert.littlecuberssolution.com/";
  front = "https://certificate.littlecuberssolution.com";
} else if (hostname.includes("juniorcubeassociation")) {
  logo = "juniorcubeassociation.png";
  server = "https://cert-api.juniorcubeassociation.com";
  client = "https://cert-dashboard.juniorcubeassociation.com";
  share = "https://cert.juniorcubeassociation.com";
  cert = "https://cert.juniorcubeassociation.com/";
  front = "https://certificate.juniorcubeassociation.com";
} else if (hostname.includes("kidskriya")) {
  logo = "littlecuberssolution.png";
  server = "https://cert-api.kidskriya.com";
  client = "https://cert-dashboard.kidskriya.com";
  share = "https://cert.kidskriya.com";
  cert = "https://cert.kidskriya.com/";
  front = "https://certificate.kidskriya.com";
} else {
  server = "https://api.certifidoc.com";
  client = "https://dashboard.certifidoc.com";
  share = "https://cert.certifidoc.com";
  cert = "https://cert.certifidoc.com/";
  front = "https://certifidoc.com";
}

module.exports = {
  logo,
  server,
  client,
  share,
  cert,
  front,
  SITE_KEY,
  canva_api_key,
  environment,
};
