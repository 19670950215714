import React, { PureComponent } from "react";
import { Route, Switch } from "react-router-dom";

import dashboard from "../source/dashboard/dashboard";
import Events from "../source/dashboard/events";
import CertificateEditor from "../source/dashboard/certificate_editor";
import AddEvent from "../source/dashboard/add_event";
import AddCredential from "../source/dashboard/add_credential";
import EventDetails from "../source/dashboard/event_details";
import Template from "../source/dashboard/template";
// Documentation
import Dev_API from "../source/dashboard/documentation/dev_api";
import Faqs from "../source/dashboard/documentation/faqs";
import Help from "../source/dashboard/documentation/help";
import Certificate_Design_Guide from "../source/dashboard/documentation/cert_design_guid";
import Contact from "../source/dashboard/documentation/contact";
// Settings
import Settings from "../source/dashboard/settings";
// Updates
import Updates from "../source/dashboard/documentation/updates";


export default class Dashboard_Router extends PureComponent {
  render() {
    return (
      <Switch>
        {/* Updates */}
        <Route path="/updates" component={Updates} />

        {/* Settings */}
        <Route path="/settings" component={Settings} />

        {/* Documentation */}
        <Route path="/dev-api" component={Dev_API} />
        <Route path="/faqs" component={Faqs} />
        <Route path="/help" component={Help} />
        <Route path="/cert-design-guide" component={Certificate_Design_Guide} />
        <Route path="/contact" component={Contact} />

        {/* Main */}
        <Route path="/event-details/:event_id" component={EventDetails} />
        <Route path="/add-credential/:event_id" component={AddCredential} />
        <Route path="/certificate-editor/:event_id" component={CertificateEditor} />
        <Route path="/events" component={Events} />
        <Route path="/templates" component={Template} />
        
        <Route path="/add-event" component={AddEvent} />
        
        <Route path="/" component={dashboard} />
      </Switch>
    );
  }
}
