import React, { PureComponent } from "react";
import { ClassicSpinner } from "react-spinners-kit";
import Flatpickr from "react-flatpickr";
import {
  Award,
  ArrowLeft,
  ArrowRight,
  Download,
  Plus,
  Settings,
  CheckCircle,
} from "react-feather";
import "flatpickr/dist/themes/material_green.css";
import isUUID from "validator/lib/isUUID";
import axios from "axios";
import Server from "../../routes/server";
import { Link } from "react-router-dom";
import Modal from "../../components/modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactExport from "react-export-excel";
import Skeleton from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import dateFormat from "dateformat";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { server, front, cert } from "../../../env";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default class Eventdetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      event_id: isUUID(this.props.match.params.event_id)
        ? this.props.match.params.event_id
        : null,
      publishedData: [],
      unpublishedData: [],
      publishedDataPerPage: 10,
      unpublishedDataPerPage: 10,
      publishedCurrentPageNumber: 1,
      unpublishedCurrentPageNumber: 1,
      checked: true,
      unchecked: false,
      check_all: false,
      creds: [],
      allCheckboxChecked: false,
      loading: false,
      requesting: true,
      publishSearchdata: [],
      publish_search_active: false,
      unpublishSearchdata: [],
      unpublish_search_active: false,
      event_details: [],
      public_name: "",
      private_name: "",
      type: "",
      start_date: null,
      end_date: null,
      description: "",
      expires: false,
      website: "",
      public_profile: "",
      share_creds: false,
      publish: false,
      publish_check: false,
      certificate_type_list: [
        "Internship",
        "Participation",
        "Excellence",
        "Achievement",
        "Qualification",
        "Appreciation",
        "Performance",
      ],
      update_spinner: false,
      changes_done: false,
      items: Array.from({ length: 6 }),
      data_loading: true,
      send_email: true,
    };
  }
  componentDidMount() {
    Server.Session_check();
    this.getEventDetails();
    this.getPublishedCredentials();
    this.getUnPublishedCredentials();
  }
  notify = (msg, toast_type) => {
    toast(msg, {
      type: toast_type,
    });
  };
  getEventDetails = () => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(server + "/event/event-details/" + this.state.event_id, config)
      .then((rsp) => {
        this.setState({
          event_details: rsp.data,
          private_name: rsp.data.private_name,
          public_name: rsp.data.public_name,
          type: rsp.data.certificate_type,
          start_date: rsp.data.start_date,
          end_date: rsp.data.end_date,
          expires: rsp.data.is_expires,
          description: rsp.data.description,
          website: rsp.data.website,
          public_profile: rsp.data.is_profile_public,
          share_creds: rsp.data.is_cred_shareable,
          active: rsp.data.active,
          publish: rsp.data.publish,
          publish_check: rsp.data.publish,
          event_name: rsp.data.private_name,
          isOther:
            this.state.certificate_type_list.indexOf(
              rsp.data.certificate_type
            ) !== -1
              ? false
              : true,
          custome_type:
            this.state.certificate_type_list.indexOf(
              rsp.data.certificate_type
            ) !== -1
              ? ""
              : rsp.data.certificate_type,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
  getPublishedCredentials = () => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(server + "/credential/show-published/" + this.state.event_id, config)
      .then((rsp) => {
        this.setState({ publishedData: rsp.data, data_loading: false });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ data_loading: false });
        }
      });
  };

  getUnPublishedCredentials = () => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(
        server + "/credential/show-unpublished/" + this.state.event_id,
        config
      )
      .then((rsp) => {
        this.setState({
          unpublishedData: rsp.data,
          requesting: false,
          data_loading: false,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ data_loading: false });
        }
      });
  };
  handleNextPagination_published = (e) => {
    e.preventDefault();
    this.setState({
      publishedCurrentPageNumber: this.state.publishedCurrentPageNumber + 1,
    });
  };
  handlePrevPagination_published = (e) => {
    e.preventDefault();
    this.setState({
      publishedCurrentPageNumber: this.state.publishedCurrentPageNumber - 1,
    });
  };

  handleNextPagination_unpublished = (e) => {
    e.preventDefault();
    this.setState({
      unpublishedCurrentPageNumber: this.state.unpublishedCurrentPageNumber + 1,
    });
  };
  handlePrevPagination_unpublished = (e) => {
    e.preventDefault();
    this.setState({
      unpublishedCurrentPageNumber: this.state.unpublishedCurrentPageNumber - 1,
    });
  };
  select_all = (e) => {
    this.state.allCheckboxChecked = e.target.checked;
    var checkboxes = document.getElementsByName("creds");
    if (this.state.allCheckboxChecked) {
      for (var i in checkboxes) {
        if (checkboxes[i].checked == false) {
          var list = this.state.creds;
          list.push(checkboxes[i].value);
          checkboxes[i].checked = true;
        }
      }
      this.setState({ creds: [...this.state.creds] });
    } else {
      for (var i in checkboxes) {
        if (checkboxes[i].checked == true) {
          checkboxes[i].checked = false;
        }
      }
      this.setState({ creds: [] });
    }
  };

  select_one = (e) => {
    if (e.target.checked) {
      this.setState({
        creds: [...this.state.creds, e.target.value],
      });
    } else {
      this.setState({
        creds: this.state.creds.filter(function (person) {
          return person !== e.target.value;
        }),
      });
    }
  };
  test = async () => {
    this.setState({ creds: [] });
    var list = [];
    await this.state.unpublishedData.forEach((item) => {
      list.push(item.cred_id);
    });
    this.setState({ creds: list });
    console.log(this.state.creds);
    this.save_publish("model_publish_all");
  };

  save_publish = (model_id) => {
    // e.preventDefault()
    this.setState({ loading: true });
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    let payload = {
      creds: this.state.creds,
      send_email: this.state.send_email,
    };
    axios
      .post(server + "/credential/publish", payload, config)
      .then((rsp) => {
        console.log(rsp);
        this.setState({ loading: false });
        this.notify(
          "All unpublished credentials have been published.",
          "success"
        );
        this.addToPublish();
        if (model_id === "model_publish_all") {
          document.getElementById("publish_all_close").click();
        }
        if (model_id === "model_publish") {
          document.getElementById("publish_close").click();
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ loading: false });
          this.notify("Some error occured. Please try again.", "error");
        }
      });
  };
  delete_credential = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .post(server + "/credential/delete-credential", this.state.creds, config)
      .then((rsp) => {
        this.setState({ loading: false });
        this.notify("Selected credentials have been deleted.", "success");
        this.removeData();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ loading: false });
          this.notify("Some error occured. Please try again.", "error");
        }
      });
  };

  addToPublish = () => {
    for (let i = 0; i < this.state.creds.length; i++) {
      var list = this.state.publishedData;
      var cred_data = this.state.creds[i];
      this.setState({
        unpublishedData: this.state.unpublishedData.filter(function (person) {
          if (person.cred_id === cred_data) {
            list.unshift(person);
          } else {
            return person.cred_id !== cred_data;
          }
        }),
      });
      var list1 = this.state.unpublishSearchdata;
      var cred_data = this.state.creds[i];
      this.setState({
        unpublishSearchdata: this.state.unpublishSearchdata.filter(function (
          person
        ) {
          if (person.cred_id === cred_data) {
            list1.unshift(person);
          } else {
            return person.cred_id !== cred_data;
          }
        }),
      });
    }
    var checkboxes = document.getElementsByName("creds");

    for (var i in checkboxes) {
      if (checkboxes[i].checked == true) {
        checkboxes[i].checked = false;
      }
    }
    this.setState({ creds: [] });
    if (this.state.allCheckboxChecked) {
      document.getElementById("select_all").click();
    }
  };

  addToUnpublish = (cred_id) => {
    var list = this.state.unpublishedData;
    var cred_data = cred_id;
    this.setState({
      publishedData: this.state.publishedData.filter(function (person) {
        if (person.cred_id === cred_data) {
          list.unshift(person);
        } else {
          return person.cred_id !== cred_data;
        }
      }),
    });
  };

  removeData = () => {
    for (let i = 0; i < this.state.creds.length; i++) {
      var list = this.state.publishedData;
      var cred_data = this.state.creds[i];
      this.setState({
        unpublishedData: this.state.unpublishedData.filter(function (person) {
          if (person.cred_id === cred_data) {
            // list.unshift(person)
          } else {
            return person.cred_id !== cred_data;
          }
        }),
      });
      var list1 = this.state.unpublishSearchdata;
      var cred_data = this.state.creds[i];
      this.setState({
        unpublishSearchdata: this.state.unpublishSearchdata.filter(function (
          person
        ) {
          if (person.cred_id === cred_data) {
            list1.unshift(person);
          } else {
            return person.cred_id !== cred_data;
          }
        }),
      });
    }
    var checkboxes = document.getElementsByName("creds");

    for (var i in checkboxes) {
      if (checkboxes[i].checked == true) {
        checkboxes[i].checked = false;
      }
    }
    this.setState({ creds: [] });
    if (this.state.allCheckboxChecked) {
      document.getElementById("select_all").click();
    }
  };

  handlePublishSearch = (e) => {
    var libraries = this.state.publishedData,
      searchString = e.target.value.trim().toLowerCase();
    if (searchString.length > 0) {
      libraries = libraries.filter(function (i) {
        return i.name.toLowerCase().match(searchString);
      });
      this.setState({
        publishSearchdata: libraries,
        publish_search_active: true,
      });
    } else {
      this.setState({ publish_search_active: false });
    }
  };
  handleUnpublishSearch = (e) => {
    var libraries = this.state.unpublishedData,
      searchString = e.target.value.trim().toLowerCase();
    if (searchString.length > 0) {
      libraries = libraries.filter(function (i) {
        return i.name.toLowerCase().match(searchString);
      });
      this.setState({
        unpublishSearchdata: libraries,
        unpublish_search_active: true,
      });
    } else {
      this.setState({ unpublish_search_active: false });
    }
  };

  onTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      changes_done: true,
    });
  };

  custome_type = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      changes_done: true,
    });
  };

  oncheckboxchage = (e) => {
    this.setState({ [e.target.name]: e.target.checked, changes_done: true });
  };

  convert_date(name, date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    var converted_date = year + "-" + month + "-" + day;
    this.setState({ [name]: converted_date, changes_done: true });
  }

  handleUpdate = (e) => {
    e.preventDefault();
    e.persist();
    this.setState({ update_spinner: true });
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const paramsss = new URLSearchParams();
    paramsss.append("public_name", this.state.public_name);
    paramsss.append("private_name", this.state.private_name);
    paramsss.append("certificate_type", this.state.type);
    this.state.isOther
      ? paramsss.append("certificate_type", this.state.custome_type)
      : paramsss.append("certificate_type", this.state.type);
    paramsss.append("website", this.state.website);
    paramsss.append("is_expires", this.state.expires);
    paramsss.append("description", this.state.description);
    paramsss.append("publish", this.state.publish);
    paramsss.append("is_profile_public", this.state.public_profile);
    paramsss.append("is_cred_shareable", this.state.share_creds);

    if (this.state.start_date !== null) {
      paramsss.append("start_date", this.state.start_date);
    }
    if (this.state.end_date !== null) {
      paramsss.append("end_date", this.state.end_date);
    }

    axios
      .post(server + "/event/update/" + this.state.event_id, paramsss, config)
      .then((rsp) => {
        this.setState({
          update_spinner: false,
          publish_check: rsp.data.publish,
          event_name: this.state.private_name,
        });
        this.notify("Event has been updated.", "success");
        // this.state.setSubmitButton === "save" ? this.props.history.push('/events') : this.props.history.push('/certificate-editor/'+rsp.data.event_id)
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ update_spinner: false });
          this.notify("Some Error occured!.", "error");
        }
      });
  };

  event_publish = () => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const paramsss = new URLSearchParams();
    this.state.publish
      ? paramsss.append("publish", false)
      : paramsss.append("publish", true);

    axios
      .post(server + "/event/publish/" + this.state.event_id, paramsss, config)
      .then((rsp) => {
        rsp.data.publish
          ? this.notify("Event has been Published.", "success")
          : this.notify("Event has been Unpublished.", "success");
        this.setState({
          publish_check: rsp.data.publish,
          publish: rsp.data.publish,
        });
        document.getElementById("event_publish_close").click();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          // this.setState({publish: !this.state.publish})
          this.notify("Some Error occured!.", "error");
        }
      });
  };
  onTypeChange = (e) => {
    if (e.target.value === "Other") {
      this.setState({ isOther: true, changes_done: true });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        isOther: false,
        changes_done: true,
      });
    }
  };

  copy = () => {
    this.notify("Link has been copied.", "success");
  };
  unpublish_cred = (cred_id) => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(server + "/credential/unpublish/" + cred_id, config)
      .then((rsp) => {
        this.notify("Credential has been unpublished.", "success");
        this.addToUnpublish(cred_id);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.notify("Some error occured. Please try again.", "error");
        }
      });
  };
  send_notification = (cred_id) => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(server + "/credential/send-notification/" + cred_id, config)
      .then((rsp) => {
        this.notify("Email has been send", "success");
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.notify("Some error occured. Please try again.", "error");
        }
      });
  };

  render() {
    const {
      publishedData,
      publishedDataPerPage,
      publishedCurrentPageNumber,
      unpublishedData,
      unpublishedDataPerPage,
      unpublishedCurrentPageNumber,
      creds,
      publishSearchdata,
      unpublishSearchdata,
      event_details,
      checked,
      unchecked,
      data_loading,
      items,
    } = this.state;
    const publishe_lastDataIndex =
      publishedCurrentPageNumber * publishedDataPerPage;
    const published_firstDataIndex =
      publishe_lastDataIndex - publishedDataPerPage;
    // const published_currentData = publishedData.slice(published_firstDataIndex, publishe_lastDataIndex)
    const published_currentData = this.state.publish_search_active
      ? publishSearchdata.slice(
          published_firstDataIndex,
          publishe_lastDataIndex
        )
      : publishedData.slice(published_firstDataIndex, publishe_lastDataIndex);

    const unpublishe_lastDataIndex =
      unpublishedCurrentPageNumber * unpublishedDataPerPage;
    const unpublished_firstDataIndex =
      unpublishe_lastDataIndex - unpublishedDataPerPage;
    // const unpublished_currentData = unpublishedData.slice(unpublished_firstDataIndex, unpublishe_lastDataIndex)
    const unpublished_currentData = this.state.unpublish_search_active
      ? unpublishSearchdata.slice(
          unpublished_firstDataIndex,
          unpublishe_lastDataIndex
        )
      : unpublishedData.slice(
          unpublished_firstDataIndex,
          unpublishe_lastDataIndex
        );

    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="row mt-4">
          <div className="col-auto">
            <div className="avatar">
              {event_details.hasOwnProperty("event_template") ? (
                event_details.event_template !== null ? (
                  <img
                    style={{ height: "75px" }}
                    src={event_details.event_template.template_img.template_img}
                    alt="..."
                    className="img-fluid rounded-lg shadow-sm"
                  />
                ) : (
                  <img
                    style={{ height: "75px" }}
                    src="../../../../assets/img/certificate_template/no_template.png"
                    alt="..."
                    className="img-fluid rounded-lg shadow-sm"
                  />
                )
              ) : (
                <Skeleton height="100%" />
              )}
            </div>
          </div>
          <div className="col-md-10 ml-n3 ml-md-n2">
            <h6 className="text-muted">Event</h6>
            {event_details.hasOwnProperty("private_name") ? (
              <div>
                <h4 style={{ lineHeight: 1.1 }} className="float-left">
                  {this.state.event_name}
                </h4>
                {this.state.publish_check ? (
                  <span class="badge badge-soft-success rounded-pill p-2 ml-2">
                    Published
                  </span>
                ) : (
                  <span class="badge badge-soft-danger rounded-pill p-2 ml-2">
                    Not Published
                  </span>
                )}{" "}
                <label className="switch mt-2">
                  <input
                    type="checkbox"
                    name="publish"
                    id="publish"
                    data-toggle="modal"
                    data-target="#event_publish"
                    checked={this.state.publish ? checked : unchecked}
                    disabled={this.state.active ? unchecked : checked}
                  />
                  <span className="slider round"></span>
                </label>
                {this.state.active ? (
                  ""
                ) : (
                  <small className="text-danger">
                    {" "}
                    You can't publish an event with no design.
                    <Link to={"/certificate-editor/" + this.state.event_id}>
                      Choose Design
                    </Link>
                  </small>
                )}
              </div>
            ) : (
              <Skeleton width="50%" />
            )}
            {/* <div className="tooltipbody"><HelpCircle color="red"/>
                  <span className="tooltiptext">The credentials are not published</span>
                </div> */}
          </div>
        </div>
        <div className="row">
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <a
                className="nav-item nav-link active"
                id="nav-overview-tab"
                data-toggle="tab"
                href="#nav-overview"
                role="tab"
                aria-controls="nav-overview"
                aria-selected="true"
              >
                Overview
              </a>
              <a
                className="nav-item nav-link"
                id="nav-setting-tab"
                data-toggle="tab"
                href="#nav-setting"
                role="tab"
                aria-controls="nav-setting"
                aria-selected="false"
              >
                Event Settings
              </a>

              <Link
                className="nav-item nav-link"
                to={"/certificate-editor/" + this.state.event_id}
              >
                Edit Event
              </Link>
            </div>
          </nav>
          <div className="tab-content px-4" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-overview"
              role="tabpanel"
              aria-labelledby="nav-home-overview"
            >
              {/* Adding success Alert on published */}
              {this.state.publish ? (
                <div
                  class="alert alert-success mt-3 col-10 mx-auto px-4 text-center"
                  role="alert"
                >
                  Your Event is published! You can let Recipients find their
                  Certificates using{" "}
                  <CopyToClipboard
                    onCopy={this.copy}
                    text={front + "/find-certificate/" + this.state.event_id}
                  >
                    <a href="javascript:void(0)" data-tip="Copy Link">
                      this link
                    </a>
                  </CopyToClipboard>
                </div>
              ) : (
                ""
              )}

              <div className="row mt-4">
                <div className="col-md-6">
                  <div className="card lift">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col">
                          <p className="text-uppercase text-muted mb-2 font-weight-bold">
                            Total Published Credential
                          </p>
                          <span className="h3 mb-0">
                            {/* {this.state.publishedData.length} */}
                            {this.state.requesting ? (
                              <ClassicSpinner
                                size={13}
                                className="d-inline-block"
                                color="#000000"
                                loading={true}
                              />
                            ) : (
                              this.state.publishedData.length
                            )}
                          </span>
                        </div>
                        <div className="col-auto mr-4">
                          <Award />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card lift">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col">
                          <p className="text-uppercase text-muted mb-2 font-weight-bold">
                            Total Unpublished Credential
                          </p>
                          <span className="h3 mb-0">
                            {this.state.requesting ? (
                              <ClassicSpinner
                                size={13}
                                className="d-inline-block"
                                color="#000000"
                                loading={true}
                              />
                            ) : (
                              this.state.unpublishedData.length
                            )}
                          </span>
                        </div>
                        <div className="col-auto mr-4">
                          <Award />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <a
                    className="nav-item nav-link active"
                    id="nav-published-tab"
                    data-toggle="tab"
                    href="#nav-published"
                    role="tab"
                    aria-controls="nav-published"
                    aria-selected="true"
                  >
                    <CheckCircle /> Published Credentials
                  </a>
                  <a
                    className="nav-item nav-link"
                    id="nav-unpublished-tab"
                    data-toggle="tab"
                    href="#nav-unpublished"
                    role="tab"
                    aria-controls="nav-unpublished"
                    aria-selected="false"
                  >
                    Un-Published Credentials
                  </a>
                </div>
              </nav>
              <div className="tab-content px-1" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-published"
                  role="tabpanel"
                  aria-labelledby="nav-home-published"
                >
                  <div className="card mt-4 shadow-sm">
                    <div className="card-header bg-transparent border-0 p-3">
                      <div className="row justify-content-between">
                        <div className="col-md-8">
                          <Link
                            to={"/add-credential/" + this.state.event_id}
                            className="btn btn-sm btn-outline-primary"
                          >
                            <Plus /> Add New Credentials
                          </Link>
                          {this.state.publishedData.length > 0 ? (
                            <ExcelFile
                              element={
                                <button
                                  className="btn btn-sm btn-outline-primary ml-2"
                                  disabled={
                                    this.state.publishedData.length > 0
                                      ? false
                                      : true
                                  }
                                >
                                  {" "}
                                  <Download /> Export Data{" "}
                                </button>
                              }
                            >
                              <ExcelSheet data={publishedData} name="sheet1">
                                <ExcelColumn
                                  label="Credential ID"
                                  value="cred_id"
                                />
                                <ExcelColumn label="Name" value="name" />
                                <ExcelColumn label="email" value="email" />
                                <ExcelColumn
                                  label="Mobile Number"
                                  value="mobile_no"
                                />
                                <ExcelColumn
                                  label="Extra Fields"
                                  value="extraFields"
                                />
                                <ExcelColumn
                                  label="Issue Date"
                                  value="issue_date"
                                />
                                <ExcelColumn
                                  label="Created"
                                  value="cred_created_on"
                                />
                              </ExcelSheet>
                            </ExcelFile>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control text-muted rounded-pill pl-3"
                            placeholder="Search by Name, Email or Number"
                            onChange={this.handlePublishSearch}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="card-body p-0 border-0">
                      <div className="table-responsive px-3">
                        <table className="table table-striped table-hover mb-0 border">
                          <thead className="border">
                            <tr className="text-center">
                              <th>Credential Id</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Mobile No.</th>
                              <th>Extra Fields</th>
                              <th>Issue Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data_loading
                              ? items.map((data, index) => (
                                  <tr>
                                    <td>
                                      <Skeleton />{" "}
                                    </td>
                                    <td>
                                      <Skeleton />
                                    </td>
                                    <td>
                                      <Skeleton />
                                    </td>
                                    <td>
                                      <Skeleton />
                                    </td>
                                    <td>
                                      <Skeleton />
                                    </td>
                                    <td>
                                      <Skeleton />
                                    </td>
                                    <td>
                                      <Skeleton />
                                    </td>
                                  </tr>
                                ))
                              : published_currentData.map((x, index) => (
                                  <tr className="text-center">
                                    <td>
                                      <a
                                        href={cert + "certificate/" + x.cred_id}
                                        className="link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {x.cred_id}
                                      </a>
                                    </td>
                                    <td>{x.name}</td>
                                    <td>{x.email}</td>
                                    <td>{x.mobile_no}</td>
                                    <td>{x.extraFields}</td>
                                    <td>
                                      {dateFormat(
                                        new Date(x.issue_date),
                                        "dd mmm yy"
                                      )}
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-danger btn-sm lift rounded"
                                        onClick={this.unpublish_cred.bind(
                                          this,
                                          x.cred_id
                                        )}
                                      >
                                        Unpublish
                                      </button>
                                      &nbsp;&nbsp;
                                      <button
                                        className="btn btn-success btn-sm lift rounded"
                                        onClick={this.send_notification.bind(
                                          this,
                                          x.cred_id
                                        )}
                                      >
                                        Send email
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="card-footer bg-transparent border-0 p-3">
                      <div className="row">
                        <div className="col-6 text-muted">
                          <h6 className="h6 pt-2 small">
                            Showing {published_currentData.length} of{" "}
                            {this.state.publishedData.length}
                          </h6>
                        </div>

                        <div className="col-6 text-right">
                          <button
                            className="btn btn-white btn-sm lift rounded-pill border px-2 mr-1"
                            onClick={this.handlePrevPagination_published}
                            disabled={
                              publishedCurrentPageNumber === 1 ? true : false
                            }
                          >
                            <ArrowLeft /> Prev
                          </button>
                          <button
                            className="btn btn-white btn-sm lift rounded-pill border px-2"
                            onClick={this.handleNextPagination_published}
                            disabled={
                              this.state.publishedData.length === 0
                                ? true
                                : Math.ceil(
                                    publishedData.length / publishedDataPerPage
                                  ) === publishedCurrentPageNumber
                                ? true
                                : false
                            }
                          >
                            Next <ArrowRight />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="nav-unpublished"
                  role="tabpanel"
                  aria-labelledby="nav-unpublished-tab"
                >
                  <div className="card mt-4 shadow-sm">
                    <div className="card-header bg-transparent border-0 p-3">
                      <div className="row justify-content-between">
                        <div className="col-md-8">
                          <Link
                            to={"/add-credential/" + this.state.event_id}
                            className="btn btn-sm btn-outline-primary"
                          >
                            <Plus /> Add New Credentials
                          </Link>
                          {this.state.unpublishedData.length > 0 ? (
                            <ExcelFile
                              element={
                                <button
                                  className="btn btn-sm btn-outline-primary ml-2"
                                  disabled={
                                    this.state.unpublishedData.length > 0
                                      ? false
                                      : true
                                  }
                                >
                                  {" "}
                                  <Download /> Export Data{" "}
                                </button>
                              }
                            >
                              <ExcelSheet data={unpublishedData} name="sheet1">
                                <ExcelColumn
                                  label="Credential ID"
                                  value="cred_id"
                                />
                                <ExcelColumn label="Name" value="name" />
                                <ExcelColumn label="email" value="email" />
                                <ExcelColumn
                                  label="Mobile Number"
                                  value="mobile_no"
                                />
                                <ExcelColumn
                                  label="Extra Fields"
                                  value="extraFields"
                                />
                                <ExcelColumn
                                  label="Issue Date"
                                  value="issue_date"
                                />
                                <ExcelColumn
                                  label="Created"
                                  value="Created On"
                                />
                              </ExcelSheet>
                            </ExcelFile>
                          ) : (
                            ""
                          )}{" "}
                          <span
                            data-tip={
                              this.state.publish
                                ? ""
                                : "Publish the event before publishing credentials"
                            }
                          >
                            <button
                              class="btn btn-sm btn-outline-primary ml-2"
                              type="button"
                              data-toggle="modal"
                              data-target="#model_publish_all"
                              onClick={() => {
                                this.setState({ send_email: true });
                              }}
                              disabled={this.state.publish ? false : true}
                            >
                              Publish All
                            </button>
                          </span>{" "}
                          <span
                            data-tip={
                              this.state.publish
                                ? this.state.creds.length > 0
                                  ? ""
                                  : "No Credential selected"
                                : "Publish the event before publishing credentials"
                            }
                          >
                            <button
                              class="btn btn-sm btn-outline-primary ml-2"
                              type="button"
                              data-toggle="modal"
                              data-target="#model_publish"
                              onClick={() => {
                                this.setState({ send_email: true });
                              }}
                              disabled={
                                this.state.publish
                                  ? this.state.creds.length > 0
                                    ? false
                                    : true
                                  : true
                              }
                            >
                              Publish Selected
                            </button>
                          </span>
                          <button
                            class="btn btn-sm btn-outline-primary ml-2"
                            type="button"
                            onClick={this.delete_credential}
                            disabled={
                              this.state.creds.length > 0 ? false : true
                            }
                          >
                            Delete
                            {this.state.loading ? (
                              <ClassicSpinner
                                size={13}
                                className="d-inline-block"
                                color="#fff"
                                loading={true}
                              />
                            ) : (
                              ""
                            )}
                          </button>
                        </div>
                        <div className="col-md-4">
                          <input
                            type="text"
                            className="form-control text-muted rounded-pill pl-3"
                            placeholder="Search by Name, Email or Number"
                            onChange={this.handleUnpublishSearch}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="card-body p-0 border-0">
                      <div className="table-responsive px-3">
                        <table className="table table-striped table-hover mb-0 border">
                          <thead className="border">
                            <tr className="text-center">
                              <th>
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="select_all"
                                  onChange={this.select_all}
                                />
                              </th>
                              <th>Credential Id</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Mobile No.</th>
                              <th>Extra Fields</th>
                              <th>Issue Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {unpublished_currentData.map((data, indedata) => (
                              <tr className="text-center">
                                <td>
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="creds"
                                    value={data.cred_id}
                                    onChange={this.select_one}
                                  />
                                </td>
                                <td>{data.cred_id}</td>
                                <td>{data.name}</td>
                                <td>{data.email}</td>
                                <td>{data.mobile_no}</td>
                                <td>{data.extraFields}</td>
                                <td>
                                  {dateFormat(
                                    new Date(data.issue_date),
                                    "dd mmm yy"
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="card-footer bg-transparent border-0 p-3">
                      <div className="row">
                        <div className="col-6 text-muted">
                          <h6 className="h6 pt-2 small">
                            Showing {unpublished_currentData.length} of{" "}
                            {this.state.unpublishedData.length}
                          </h6>
                        </div>

                        <div className="col-6 text-right">
                          <button
                            className="btn btn-white btn-sm lift rounded-pill border px-3"
                            onClick={this.handlePrevPagination_unpublished}
                            disabled={
                              unpublishedCurrentPageNumber === 1 ? true : false
                            }
                          >
                            <ArrowLeft /> Prev
                          </button>
                          <button
                            className="btn btn-white btn-sm lift rounded-pill border px-3"
                            onClick={this.handleNextPagination_unpublished}
                            disabled={
                              this.state.unpublishedData.length === 0
                                ? true
                                : Math.ceil(
                                    unpublishedData.length /
                                      unpublishedDataPerPage
                                  ) === unpublishedCurrentPageNumber
                                ? true
                                : false
                            }
                          >
                            Next <ArrowRight />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="nav-setting"
              role="tabpanel"
              aria-labelledby="nav-setting-tab"
            >
              <div className="card my-5 shadow-sm">
                <div className="card-body p-3 border-0">
                  <form onSubmit={this.handleUpdate}>
                    <div className="row p-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="public_name">Display Name</label>
                          <br></br>
                          <small className="form-text text-muted">
                            {" "}
                            This name will be visible to public on Certificates
                            and Emails
                          </small>
                          <input
                            type="text"
                            name="public_name"
                            id="public_name"
                            className="form-control rounded-lg mt-2 p-2"
                            autoFocus={true}
                            required
                            placeholder="Display Name"
                            value={this.state.public_name}
                            onChange={this.onTextChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="private_name">Event Name</label>
                          <br></br>
                          <small className="form-text text-muted">
                            This name will only be visible on your Dashboard
                          </small>
                          <input
                            type="text"
                            name="private_name"
                            id="private_name"
                            className="form-control rounded-lg mt-2 p-2"
                            autoFocus={true}
                            required
                            placeholder="Event Name"
                            value={this.state.private_name}
                            onChange={this.onTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row p-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="type">Certificate Type</label>
                          <select
                            className="form-control mt-2 p-2"
                            id="type"
                            name="type"
                            onChange={this.onTypeChange}
                            required
                          >
                            <option value="">Select Type</option>
                            {this.state.isOther
                              ? this.state.certificate_type_list.map(
                                  (x, index) => <option value={x}>{x}</option>
                                )
                              : this.state.certificate_type_list.map(
                                  (x, index) =>
                                    this.state.type === x ? (
                                      <option value={x} selected>
                                        {x}
                                      </option>
                                    ) : (
                                      <option value={x}>{x}</option>
                                    )
                                )}
                            {this.state.isOther ? (
                              <option value="Other" selected>
                                Other
                              </option>
                            ) : (
                              <option value="Other">Other</option>
                            )}
                          </select>
                        </div>
                      </div>
                      {this.state.isOther ? (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="type1">
                              Please specify certificate type{" "}
                              <b className="text-danger">*</b>
                            </label>
                            <br></br>
                            <input
                              type="text"
                              name="custome_type"
                              id="type1"
                              className="form-control rounded-lg mt-2 p-2"
                              required
                              onChange={this.custome_type}
                              value={this.state.custome_type}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="publish">Publish</label>
                  <br></br>
                      <label className="switch mt-2">
                        <input type="checkbox"
                        name="publish"
                        id="publish"
                        onChange={this.oncheckboxchage}
                        checked={this.state.publish ? checked : unchecked}
                        disabled={this.state.active ? unchecked : checked}
                        />
                        <span className="slider round"></span>
                      </label> {this.state.active ? "" : <small className="text-danger">{" "}you cant publish event with no design. <Link to={"/certificate-editor/"+this.state.event_id}>Choose Design</Link></small>}
                  </div>
                </div> */}
                    </div>
                    <div className="row pb-3 mt-4">
                      <div className="col-md-12">
                        <hr></hr>
                        <h7 class="text-uppercase text-muted font-weight-bold">
                          {" "}
                          About Event{" "}
                        </h7>
                      </div>
                    </div>
                    <div className="row p-3">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="start_data">Start Date</label>
                          <Flatpickr
                            defaultValue="Select Date"
                            value={this.state.start_date}
                            className="form-control rounded-lg mt-2 p-2"
                            onChange={(start_date) => {
                              this.convert_date("start_date", start_date[0]);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="end_data">End Date</label>
                          <Flatpickr
                            defaultValue="Select Date"
                            value={this.state.end_date}
                            className="form-control rounded-lg mt-2 p-2"
                            onChange={(end_date) => {
                              this.convert_date("end_date", end_date[0]);
                            }}
                            options={{ minDate: this.state.start_date }}
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                  <div className="form-group">
                  <label htmlFor="expires">Expires (Choose if Certificate Expires)</label>
                  <br></br>
                      <label className="switch mt-2">
                        <input type="checkbox"
                        name="expires"
                        id="expires"
                        className="form-control"
                        onChange={this.oncheckboxchage}
                        checked={this.state.expires ? checked : unchecked}
                        />
                        <span className="slider round"></span>
                      </label>
                  </div>
                </div> */}
                    </div>
                    <div className="row p-3">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="description">Event Description</label>
                          <br></br>
                          <small className="form-text text-muted">
                            {" "}
                            This contact will be shown to others publicly, so
                            choose it carefully.
                          </small>
                          <textarea
                            className="form-control rounded-lg h-75 mt-1"
                            placeholder="Description"
                            id="description"
                            name="description"
                            value={this.state.description}
                            onChange={this.onTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row p-3">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="website">
                            Your Company's Website Domain
                          </label>
                          <br></br>
                          <small className="form-text text-muted">
                            {" "}
                            Visitor will be redirected to this when they see
                            your Website.
                          </small>
                          <input
                            type="url"
                            name="website"
                            id="website"
                            className="form-control rounded-lg mt-2 p-2"
                            autoFocus={true}
                            placeholder="https://www.example.com"
                            value={this.state.website}
                            onChange={this.onTextChange}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="row p-3">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="public_profile">Public Profile</label>
                    <br></br>
                    <small className="text-muted">Making your profile public means that anyone on the web can see your event Details and Other participants</small>
                    <br></br>
                    <label className="switch mt-2">
                        <input type="checkbox"
                        name="public_profile"
                        id="public_profile"
                        className="form-control"
                        onChange={this.oncheckboxchage}
                        checked={this.state.public_profile ? checked : unchecked}
                        />
                        <span className="slider round"></span>
                      </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="share_creds">Allow for Credentials to be shared</label>
                    <br></br>
                    <small className="text-muted">If you are available for hire outside of the current situation, you can encourage others to hire you.</small>
                    <br></br>
                    <label className="switch mt-2">
                        <input type="checkbox"
                        name="share_creds"
                        id="share_creds"
                        className="form-control"
                        onChange={this.oncheckboxchage}
                        checked={this.state.share_creds ? checked : unchecked}
                        />
                        <span className="slider round"></span>
                      </label>
                  </div>
                </div>
              </div> */}
                    <div className="form-group mt-5 mb-0">
                      <button
                        class="btn btn-primary lift float-right"
                        type="submit"
                        disabled={this.state.changes_done ? unchecked : checked}
                      >
                        Update{" "}
                        {this.state.update_spinner ? (
                          <ClassicSpinner
                            size={13}
                            className="d-inline-block"
                            color="#fff"
                            loading={true}
                          />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          key="model_publish_all"
          id="model_publish_all"
          title="Publish All Credentials"
          close_id="publish_all_close"
        >
          <div className="modal-body text-center p-4 mb-3">
            <div class="alert alert-warning mx-auto  text-center" role="alert">
              This action will publish ALL the certificates from unpublished
              list
            </div>
            <div class="alert alert-primary mx-auto  text-center" role="alert">
              When you hit Yes and Publish these credentials, we will notify
              selected recipients on their Emails and send them their
              certificates' public link.
            </div>

            <h5>Are you sure you want to publish ALL credentials?</h5>
            <div className="mb-3 form-check mt-2 text-left">
              <input
                type="checkbox"
                className="form-check-input"
                name="send_email"
                checked={this.state.send_email ? true : false}
                onChange={() => {
                  this.setState({ send_email: !this.state.send_email });
                }}
              />
              <label className="form-check-label" for="exampleCheck1">
                Send email notification to users.
              </label>
            </div>

            <div className="mt-4">
              <button
                className="btn btn-danger  rounded-pill rounded-lg"
                data-dismiss="modal"
              >
                No
              </button>
              <button
                className="btn btn-success rounded-pill ml-2"
                onClick={this.test}
              >
                Yes{" "}
                {this.state.loading ? (
                  <ClassicSpinner
                    size={13}
                    className="d-inline-block"
                    color="#fff"
                    loading={true}
                  />
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          key="model_publish"
          id="model_publish"
          title="Publish Credentials"
          close_id="publish_close"
        >
          <div className="modal-body text-center p-4 mb-3">
            <div class="alert alert-primary mx-auto  text-center" role="alert">
              When you hit Yes and Publish these credentials, we will notify
              selected recipients on their Emails and send them their
              certificates' public link.
            </div>

            <h5>Are you sure you want to publish selected credentials?</h5>
            <div className="mb-3 form-check mt-2 text-left">
              <input
                type="checkbox"
                className="form-check-input"
                name="send_email"
                checked={this.state.send_email ? true : false}
                onChange={() => {
                  this.setState({ send_email: !this.state.send_email });
                }}
              />
              <label className="form-check-label" for="exampleCheck1">
                Send email notification to users.
              </label>
            </div>

            <div className="mt-4">
              <button
                className="btn btn-danger  rounded-pill rounded-lg"
                data-dismiss="modal"
              >
                No
              </button>
              <button
                className="btn btn-success rounded-pill ml-2"
                onClick={this.save_publish.bind(this, "model_publish")}
              >
                Yes{" "}
                {this.state.loading ? (
                  <ClassicSpinner
                    size={13}
                    className="d-inline-block"
                    color="#fff"
                    loading={true}
                  />
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          key="event_publish"
          id="event_publish"
          title={this.state.publish ? "Unpublish Event!" : "Publish Event!"}
          close_id="event_publish_close"
        >
          <div className="modal-body text-center p-4 mb-3">
            {this.state.publish ? (
              <h5>
                This action will make all the issued/published certificates
                provided to recipients invisible from public.
              </h5>
            ) : (
              <h5>
                This action will make this event published and all the
                credentials that are published will be visible to public.
              </h5>
            )}
            {this.state.publish ? (
              <h6>Are you sure you still want to unpublish this event?</h6>
            ) : (
              <h6>Are you sure you want to publish this this event?</h6>
            )}

            <div className="mt-4">
              <button
                className="btn btn-danger  rounded-pill rounded-lg"
                data-dismiss="modal"
              >
                No
              </button>
              <button
                className="btn btn-success rounded-pill ml-2"
                onClick={this.event_publish}
              >
                Yes{" "}
                {this.state.loading ? (
                  <ClassicSpinner
                    size={13}
                    className="d-inline-block"
                    color="#fff"
                    loading={true}
                  />
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </Modal>
        {/* <div style={{ marginTop: 20 }}>{JSON.stringify(this.state.publishedData)}</div> */}

        <ToastContainer />
        <ReactTooltip />
      </main>
    );
  }
}
