import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { AlertTriangle } from "react-feather";
import * as Icon from "react-feather";

import Modal from "../../components/modal";
import { ClassicSpinner } from "react-spinners-kit";
import { Rnd } from "react-rnd";
import Server from "../../routes/server";
import "../../css/styles.scss";
import $ from "jquery";
import isUUID from "validator/lib/isUUID";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QRCode from "qrcode.react";
import { isMobile } from "react-device-detect";
import { server, canva_api_key } from "../../../env";

export default class CertificateEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      event_id: isUUID(this.props.match.params.event_id)
        ? this.props.match.params.event_id
        : null,
      font_size: 35,
      font_face: "times new roman, times",
      font_text: "Choose font",
      test_name: "[Recipient Name]",
      center_text: "Center",
      text_x: 0,
      text_y: 0,
      qr_x: 0,
      qr_y: 0,
      qr_front_color: "#000000",
      qr_back_color: "#ffffff",
      text_width: 300,
      text_height: 80,
      qr_width: 100,
      qr_height: 100,
      qr_auto_stick_enabled: false,
      text_auto_stick_enabled: false,
      template_selection_view: true,
      template_data: [],
      next_url: null,

      color: "#000000",
      active: false,
      template_name: "",
      spinner: false,
      show_more: false,

      tab1_class: "tab-pane active",
      tab2_class: "tab-pane ",
      tab3_class: "tab-pane ",
      nav1_class: "nav-link active",
      nav2_class: "nav-link ",
      nav3_class: "nav-link ",
      center_lock_text: false,
      center_lock_qr: false,
      template_img_path: "",
      is_update: false,
      edit_template_id: "",
      loaded: false,
      is_mobile: isMobile,

      // v1.1
      textAlign: "center",

      // v1.3
      extraFields: [],
      activeIndex: 0,
      fontSize: 20,
      fontColor:  "#000000",
    };
  }
  componentDidMount() {
    Server.Session_check();
    this.get_event_details();
    this.getTemplate();
  }

  notify = (msg, toast_type) => {
    toast(msg, {
      type: toast_type,
    });
  };

  tab1_active = () => {
    this.setState({
      tab1_class: "tab-pane active",
      tab2_class: "tab-pane ",
      tab3_class: "tab-pane ",
      nav1_class: "nav-link active",
      nav2_class: "nav-link ",
      nav3_class: "nav-link ",
    });
  };
  tab2_active = () => {
    this.setState({
      tab1_class: "tab-pane ",
      tab2_class: "tab-pane active",
      tab3_class: "tab-pane ",
      nav1_class: "nav-link ",
      nav2_class: "nav-link active",
      nav3_class: "nav-link ",
    });
  };
  tab3_active = async () => {
    await this.setState({
      tab1_class: "tab-pane ",
      tab2_class: "tab-pane ",
      tab3_class: "tab-pane active",
      nav1_class: "nav-link ",
      nav2_class: "nav-link ",
      nav3_class: "nav-link active",
    });
  };

  get_event_details = () => {
    var url = window.location.href;
    var target = url.split("/");
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(server + "/event/event-details/" + target[target.length - 1], config)
      .then((rsp) => {
        if (rsp.data.active) {
          var extraFields = JSON.parse(rsp.data.event_template.extraFields);
          this.setState({
            event_name: rsp.data.private_name,
            template_selection_view: false,
            edit_template_id: rsp.data.event_template.edit_template_id,
            is_update: true,
            template_id: rsp.data.event_template.template_img.template_id,
            template_img: rsp.data.event_template.template_img.template_img,
            text_width: rsp.data.event_template.text_width,
            text_height: rsp.data.event_template.text_height,
            text_x: rsp.data.event_template.text_x_cord,
            text_y: rsp.data.event_template.text_y_cord,
            font_face: rsp.data.event_template.font_face,
            font_text: rsp.data.event_template.font_name,
            font_size: rsp.data.event_template.font_size,
            color: rsp.data.event_template.font_color,
            textAlign: rsp.data.event_template.font_align,
            qr_width: rsp.data.event_template.qr_width,
            qr_height: rsp.data.event_template.qr_height,
            qr_x: rsp.data.event_template.qr_x_cord,
            qr_y: rsp.data.event_template.qr_y_cord,
            qr_front_color: rsp.data.event_template.qr_front_color,
            qr_back_color: rsp.data.event_template.qr_back_color,
            extraFields,
          });
        } else {
          var extraFields = [];
          for (let i = 0; i < rsp.data.extraFields; i++) {
            extraFields.push({
              x: 0,
              y: 0,
              w: 100,
              h: 100,
              fontSize: 20,
              fontFamily: this.state.font_face,
              fontText: this.state.font_text,
              fontColor: this.state.font_text,
              textAlign: "center",
            });
          }

          this.setState({
            extraFields,
            event_name: rsp.data.private_name,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error);
        }
      });
  };

  getTemplate = () => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const params = new URLSearchParams();
    params.append("event_id", this.state.event_id);
    axios
      .get(server + "/template/my-template", config)
      .then((rsp) => {
        this.setState({
          template_data: rsp.data.results,
          next_url: rsp.data.next,
          loaded: true,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };

  handleChange = (event) => {
    this.setState({ font_size: event.target.value });
  };

  set_font = (name, e) => {
    console.log(name, e);
    this.setState({ font_face: e.target.value, font_text: name });
  };
  text_center = () => {
    var w = this.templateElement.offsetWidth;
    var qrw = this.state.text_width;
    var center_left_value = (w - qrw) / 2;
    this.setState({ text_x: center_left_value });
  };
  text_auto_stick = (e) => {
    this.setState({ text_auto_stick_enabled: e.target.checked });
    this.text_center();
  };
  qr_center = () => {
    var w = this.templateElement.offsetWidth;
    var qrw = this.state.qr_width;
    var center_left_value = (w - qrw) / 2;
    this.setState({ qr_x: center_left_value });
  };
  qr_auto_stick = (e) => {
    this.setState({ qr_auto_stick_enabled: e.target.checked });
    this.qr_center();
  };

  long_name = (e) => {
    if (e.target.value === "") {
      this.setState({ test_name: "[ Recipient Name ]" });
    } else {
      this.setState({ test_name: e.target.value });
    }
  };
  selectTemplate = (id, img) => {
    this.setState({
      template_selection_view: false,
      template_id: id,
      template_img: img,
    });
  };

  handleColorChange = (e) => {
    this.setState({
      color: e.target.value,
      active: !this.state.active,
    });
  };

  handleQrColorChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  save = (e) => {
    e.preventDefault();
    this.setState({ spinner: true });
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const params = new URLSearchParams();
    params.append("event_id", this.state.event_id);
    params.append("template_img", this.state.template_id);
    params.append("text_width", this.state.text_width);
    params.append("text_height", this.state.text_height);
    params.append("text_x_cord", this.state.text_x);
    params.append("text_y_cord", this.state.text_y);
    params.append("font_face", this.state.font_face);
    params.append("font_name", this.state.font_text);
    params.append("font_size", this.state.font_size);
    params.append("font_color", this.state.color);
    params.append("font_align", this.state.textAlign);
    params.append("qr_width", this.state.qr_width);
    params.append("qr_height", this.state.qr_height);
    params.append("qr_x_cord", this.state.qr_x);
    params.append("qr_y_cord", this.state.qr_y);

    params.append("qr_front_color", this.state.qr_front_color);
    params.append("qr_back_color", this.state.qr_back_color);

    params.append("template", this.state.template_id);

    params.append("text_x_cord_percent", this.templateElement.offsetWidth);
    params.append("text_y_cord_percent", this.templateElement.offsetHeight);
    params.append("extraFields", JSON.stringify(this.state.extraFields));

    axios
      .post(server + "/edit-template/create", params, config)
      .then((rsp) => {
        this.setState({ spinner: false, is_update: true });
        this.notify("Template has been saved..", "success");
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ spinner: false });
        }
      });
  };

  update = (e) => {
    e.preventDefault();
    this.setState({ spinner: true });
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const params = new URLSearchParams();
    params.append("text_width", this.state.text_width);
    params.append("text_height", this.state.text_height);
    params.append("text_x_cord", this.state.text_x);
    params.append("text_y_cord", this.state.text_y);
    params.append("font_face", this.state.font_face);
    params.append("font_name", this.state.font_text);
    params.append("font_size", this.state.font_size);
    params.append("font_color", this.state.color);
    params.append("font_align", this.state.textAlign);
    params.append("qr_width", this.state.qr_width);
    params.append("qr_height", this.state.qr_height);
    params.append("qr_x_cord", this.state.qr_x);
    params.append("qr_y_cord", this.state.qr_y);
    params.append("template_img", this.state.template_id);
    params.append("template", this.state.template_id);

    params.append("qr_front_color", this.state.qr_front_color);
    params.append("qr_back_color", this.state.qr_back_color);

    params.append("text_x_cord_percent", this.templateElement.offsetWidth);
    params.append("text_y_cord_percent", this.templateElement.offsetHeight);
    params.append("extraFields", JSON.stringify(this.state.extraFields));

    console.log(params);

    axios
      .post(
        server + "/edit-template/update/" + this.state.edit_template_id,
        params,
        config
      )
      .then((rsp) => {
        this.setState({ spinner: false });
        this.notify("Template has been updated.", "success");
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ spinner: false });
        }
      });
  };
  upload_template = (e) => {
    e.preventDefault();
    this.setState({ spinner: true });
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const params = new FormData();
    params.append("template_img", this.state.template_img_path);
    axios
      .post(server + "/template/create", params, config)
      .then((rsp) => {
        this.notify("Template has been uploaded.", "success");
        document.getElementById("temp_upload").click();

        this.setState({
          spinner: false,
          template_selection_view: false,
          template_img: rsp.data.template_img,
          template_id: rsp.data.template_id,
        });
      })

      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ spinner: false });
        }
      });
  };

  paginationData = (url) => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(url, config)
      .then((rsp) => {
        let response = rsp.data.results;
        let comments = this.state.template_data;
        var i;
        let single_res;
        for (i = 0; i < response.length; i++) {
          single_res = rsp.data.results[i];
          comments.push(single_res);
        }

        this.setState({
          template_data: comments,
          next_url: rsp.data.next,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };
  text_center_lock = () => {
    this.setState({ center_lock_text: true, text_auto_stick_enabled: true });
    this.text_center();
  };
  text_center_unlock = () => {
    this.setState({ center_lock_text: false, text_auto_stick_enabled: false });
  };
  qr_center_lock = () => {
    this.setState({ center_lock_qr: true, qr_auto_stick_enabled: true });
    this.qr_center();
  };
  qr_center_unlock = () => {
    this.setState({ center_lock_qr: false, qr_auto_stick_enabled: false });
  };
  createFile = async (url, id) => {
    this.setState({ loaded: false });
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type: "image/jpeg",
    };
    let file = new File([data], "temp.jpg", metadata);
    this.setState({ template_img_path: file });
    this.upload_template_by_canva(file, id);
  };
  upload_template_by_canva = (file, id) => {
    console.log(file);
    // e.preventDefault()
    // this.setState({spinner:true})
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const params = new FormData();
    params.append("template_img", file);
    params.append("design_id", id);
    axios
      .post(server + "/template/create", params, config)
      .then((rsp) => {
        console.log(rsp);
        this.notify("Template has been uploaded.", "success");
        let response = {
          template_id: rsp.data.template_id,
          template_img: rsp.data.template_img,
          template_created_on: rsp.data.template_created_on,
          template_created_by: rsp.data.template_created_by,
        };
        const template_data = Object.assign([], this.state.template_data);
        template_data.unshift(response);
        this.setState({ template_data: template_data, loaded: true });
      })

      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ spinner: false, loaded: true });
        }
      });
  };
  loadCanvaSDK = () => {
    ((document, url) => {
      var script = document.createElement("script");
      script.src = url;
      script.onload = () => {
        // API initialization
        (async () => {
          if (window.Canva && window.Canva.DesignButton) {
            const api = await window.Canva.DesignButton.initialize({
              apiKey: canva_api_key,
            });
            // Use "api" object or save for later
            api.createDesign({
              design: {
                type: "Certificate",
              },
              onDesignOpen: ({ designId }) => {
                // Triggered when editor finishes loading and opens a new design.
                // You can save designId for future use.
              },
              onDesignPublish: ({ exportUrl, designId }) => {
                this.createFile(exportUrl, designId);
                // Triggered when design is published to an image.
                // Save the image to your server as the exportUrl will expire shortly.
              },
              onDesignClose: () => {
                // Triggered when editor is closed.
              },
            });
          }
        })();
      };
      document.body.appendChild(script);
    })(document, "https://sdk.canva.com/designbutton/v2/api.js");
  };

  // v1.3 extraFields
  setExtraFieldsXY = async (x, y, index) => {
    console.log(x, y);
    var extraFields = this.state.extraFields;

    await this.setState((state) => {
      var zones = [];
      for (let i = 0; i < extraFields.length; i++) {
        if (i === index) {
          zones.push({
            x,
            y,
            w: extraFields[i].w,
            h: extraFields[i].h,
            fontSize: extraFields[i].fontSize,
            fontFamily: extraFields[i].fontFamily,
            fontText: extraFields[i].fontText,
            fontColor: extraFields[i].fontColor,
            textAlign: extraFields[i].textAlign,
          });
        } else {
          zones.push(extraFields[i]);
        }
      }
      return { extraFields: zones };
    });
  };

  setExtraFieldsWH = async (ref, position, index) => {
    var extraFields = this.state.extraFields;

    await this.setState((state) => {
      var zones = [];
      for (let i = 0; i < extraFields.length; i++) {
        if (i === index) {
          zones.push({
            x: position.x,
            y: position.y,
            w: ref.offsetWidth,
            h: ref.offsetHeight,
            fontSize: extraFields[i].fontSize,
            fontFamily: extraFields[i].fontFamily,
            fontText: extraFields[i].fontText,
            fontColor: extraFields[i].fontColor,
            textAlign: extraFields[i].textAlign,
          });
        } else {
          zones.push(extraFields[i]);
        }
      }
      return { extraFields: zones };
    });
  };

  setActiveIndex = (index) => {
    this.setState({
      activeIndex: index,
    });
  };

  setExtraFieldsFont = async (name, e) => {
    var extraFields = this.state.extraFields;
    const fontFamily = e.target.value;

    await this.setState((state) => {
      var zones = [];
      for (let i = 0; i < extraFields.length; i++) {
        if (i === this.state.activeIndex) {
          zones.push({
            x: extraFields[i].x,
            y: extraFields[i].y,
            w: extraFields[i].w,
            h: extraFields[i].h,
            fontSize: extraFields[i].fontSize,
            fontFamily,
            fontText: name,
            fontColor: extraFields[i].fontColor,
            textAlign: extraFields[i].textAlign,
          });
        } else {
          zones.push(extraFields[i]);
        }
      }
      return { extraFields: zones };
    });
  };

  setExtraFieldsFontSize = async (e) => {
    var extraFields = this.state.extraFields;
    const fontSize = e.target.value;
    this.setState({
      fontSize,
    });
    await this.setState((state) => {
      var zones = [];
      for (let i = 0; i < extraFields.length; i++) {
        if (i === this.state.activeIndex) {
          zones.push({
            x: extraFields[i].x,
            y: extraFields[i].y,
            w: extraFields[i].w,
            h: extraFields[i].h,
            fontSize,
            fontFamily: extraFields[i].fontFamily,
            fontText: extraFields[i].fontText,
            fontColor: extraFields[i].fontColor,
            textAlign: extraFields[i].textAlign,
          });
        } else {
          zones.push(extraFields[i]);
        }
      }
      return { extraFields: zones };
    });
  };

  setExtraFieldsAlign = async (textAlign) => {
    var extraFields = this.state.extraFields;

    await this.setState((state) => {
      var zones = [];
      for (let i = 0; i < extraFields.length; i++) {
        if (i === this.state.activeIndex) {
          zones.push({
            x: extraFields[i].x,
            y: extraFields[i].y,
            w: extraFields[i].w,
            h: extraFields[i].h,
            fontSize: extraFields[i].fontSize,
            fontFamily: extraFields[i].fontFamily,
            fontText: extraFields[i].fontText,
            fontColor: extraFields[i].fontColor,
            textAlign: textAlign,
          });
        } else {
          zones.push(extraFields[i]);
        }
      }
      return { extraFields: zones };
    });
  };

  setExtraFieldsColor = async (e) => {
    var extraFields = this.state.extraFields;
    const fontColor = e.target.value;
    this.setState({
      fontColor,
    });
    await this.setState((state) => {
      var zones = [];
      for (let i = 0; i < extraFields.length; i++) {
        if (i === this.state.activeIndex) {
          zones.push({
            x: extraFields[i].x,
            y: extraFields[i].y,
            w: extraFields[i].w,
            h: extraFields[i].h,
            fontSize: extraFields[i].fontSize,
            fontFamily: extraFields[i].fontFamily,
            fontText: extraFields[i].fontText,
            fontColor,
            textAlign: extraFields[i].textAlign,
          });
        } else {
          zones.push(extraFields[i]);
        }
      }
      return { extraFields: zones };
    });
  };

  render() {
    const { template_data, textAlign, extraFields, activeIndex } = this.state;
    const style = {
      display: "flex",
      alignItems: "center",
      justifyContent: textAlign,
      border: "solid 1px #2c7be5",
    };
    const qr_style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "solid 1px #2c7be5",
      backgroundColor: this.state.qr_back_color,
      padding: "3px 3px 3px 3px",
    };
    const font = {
      fontSize: this.state.font_size + "px",
      fontFamily: this.state.font_face,
      color: this.state.color,
    };

    return (
      // Show warning messagw on mobile device that we currently does not support certificate editor on mobile view
      this.state.is_mobile ? (
        <div class="jumbotron d-flex align-items-center min-vh-100">
          <div class="container text-center alert-icon">
            <AlertTriangle />
            <br></br>
            We currently do not support Editor on Mobile Phone. Please Try on a
            PC or a Laptop
          </div>
        </div>
      ) : this.state.loaded ? (
        <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
          <div className="card mt-4 shadow-sm">
            <div className="d-flex justify-content-between p-3">
              {this.state.template_selection_view ? (
                <h3 className="card-title h5 mb-0">Choose a Template</h3>
              ) : (
                <h3 className="card-title h5 mb-0">{this.state.event_name}</h3>
              )}
              {this.state.template_selection_view ? (
                ""
              ) : (
                <div className="btn-toolbar mb-0 mb-md-0">
                  <Link
                    className="btn btn-success btn-sm rounded-pill pl-3 pr-3 mr-1"
                    to={"/event-details/" + this.state.event_id}
                  >
                    Go to Event
                  </Link>
                  <button
                    className="btn btn-primary btn-sm rounded-pill pl-3 pr-3 mr-1"
                    onClick={() => {
                      this.setState({ template_selection_view: true });
                    }}
                  >
                    Change Template
                  </button>
                  {this.state.is_update ? (
                    <button
                      className="btn btn-primary btn-sm rounded-pill pl-3 pr-3"
                      onClick={this.update}
                    >
                      Update Template{" "}
                      {this.state.spinner ? (
                        <ClassicSpinner
                          size={13}
                          className="d-inline-block"
                          color="#fff"
                          loading={true}
                        />
                      ) : (
                        ""
                      )}
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary btn-sm rounded-pill pl-3 pr-3"
                      onClick={this.save}
                    >
                      Save Template{" "}
                      {this.state.spinner ? (
                        <ClassicSpinner
                          size={13}
                          className="d-inline-block"
                          color="#fff"
                          loading={true}
                        />
                      ) : (
                        ""
                      )}
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
          {this.state.template_selection_view ? (
            ""
          ) : (
            <div
              className="card mt-4 py-4 pl-3 pr-3"
              style={{ height: "auto" }}
            >
              <div className="card-header bg-transparent p-1 border-0">
                <div className="row  justify-content-between">
                  <div className="tab-content">
                    <ul className="nav nav-pills mb-3" role="tablist">
                      <li className="nav-item">
                        <a
                          className={this.state.nav1_class}
                          href="#tab1"
                          role="tab"
                          data-toggle="tab"
                          onClick={this.tab1_active}
                        >
                          Display Text
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={this.state.nav2_class}
                          href="#tab2"
                          role="tab"
                          data-toggle="tab"
                          onClick={this.tab2_active}
                        >
                          QR Code
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={this.state.nav3_class}
                          href="#tab3"
                          role="tab"
                          data-toggle="tab"
                          id="tab3"
                          onClick={this.tab3_active}
                        >
                          Extra Fields
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="tab-content">
                    <div
                      role="tabpanel"
                      className={this.state.tab1_class}
                      id="tab1"
                    >
                      <div className="row g-4 justify-content-center">
                        <div className="col-md-auto">
                          <label htmlFor="">Choose Font Family</label> <br />
                          <div class="btn-group dropdown">
                            <button
                              type="button"
                              class="btn btn-primary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              id="choose_font_text"
                              aria-expanded="false"
                              style={{
                                fontFamily: this.state.font_face,
                                height: "31px",
                                paddingTop: "3px",
                              }}
                            >
                              {this.state.font_text}
                            </button>
                            <div class="dropdown-menu" id="select_font">
                              <button
                                class="dropdown-item"
                                type="button"
                                // value=""
                                onClick={this.set_font.bind(this, "Avenir")}
                                value="avenir, arial, helvetica, sans-serif"
                                style={{
                                  fontFamily:
                                    "avenir, arial, helvetica, sans-serif",
                                }}
                              >
                                Avenir
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="andale mono, times"
                                onClick={this.set_font.bind(
                                  this,
                                  "Andale Mono"
                                )}
                                style={{ fontFamily: "andale mono, times" }}
                              >
                                Andale Mono
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="arial, helvetica, sans-serif"
                                onClick={this.set_font.bind(this, "Arial")}
                                style={{
                                  fontFamily: "arial, helvetica, sans-serif",
                                }}
                              >
                                Arial
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="arial black, avant garde"
                                onClick={this.set_font.bind(
                                  this,
                                  "Arial Black"
                                )}
                                style={{
                                  fontFamily: "arial black, avant garde",
                                }}
                              >
                                Arial Black
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="book antiqua, palatino"
                                onClick={this.set_font.bind(
                                  this,
                                  "Book Antiqua"
                                )}
                                style={{ fontFamily: "book antiqua, palatino" }}
                              >
                                Book Antiqua
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="comic sans ms, sans-serif"
                                onClick={this.set_font.bind(
                                  this,
                                  "Comic Sans MS"
                                )}
                                style={{
                                  fontFamily: "comic sans ms, sans-serif",
                                }}
                              >
                                Comic Sans MS
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="courier new, courier"
                                onClick={this.set_font.bind(
                                  this,
                                  "Courier New"
                                )}
                                style={{ fontFamily: "courier new, courier" }}
                              >
                                Courier New
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="georgia, palatino"
                                onClick={this.set_font.bind(this, "Georgia")}
                                style={{ fontFamily: "georgia, palatino" }}
                              >
                                Georgia
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="helvetica"
                                onClick={this.set_font.bind(this, "Helvetica")}
                                style={{ fontFamily: "helvetica" }}
                              >
                                Helvetica
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="impact, chicago"
                                onClick={this.set_font.bind(this, "Impact")}
                                style={{ fontFamily: "impact, chicago" }}
                                // style={{ fontFamily: "" }}
                              >
                                Impact
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="tahoma, arial, helvetica, sans-serif"
                                onClick={this.set_font.bind(this, "Tahoma")}
                                style={{
                                  fontFamily:
                                    "tahoma, arial, helvetica, sans-serif",
                                }}
                              >
                                Tahoma
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="terminal, monaco"
                                onClick={this.set_font.bind(this, "Terminal")}
                                style={{ fontFamily: "terminal, monaco" }}
                              >
                                Terminal
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="times new roman, times"
                                onClick={this.set_font.bind(
                                  this,
                                  "Times New Roman"
                                )}
                                style={{ fontFamily: "times new roman, times" }}
                              >
                                Times New Roman
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="trebuchet ms, geneva"
                                onClick={this.set_font.bind(
                                  this,
                                  "Trebuchet MS"
                                )}
                                style={{ fontFamily: "trebuchet ms, geneva" }}
                              >
                                Trebuchet MS
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="verdana, geneva"
                                onClick={this.set_font.bind(this, "Verdana")}
                                style={{ fontFamily: "verdana, geneva" }}
                              >
                                Verdana
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Aclonica, sans-serif"
                                onClick={this.set_font.bind(this, "Aclonica")}
                                style={{ fontFamily: "Aclonica, sans-serif" }}
                              >
                                Aclonica
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Alfa Slab One, cursive"
                                onClick={this.set_font.bind(
                                  this,
                                  "Alfa Slab One"
                                )}
                                style={{ fontFamily: "Alfa Slab One, cursive" }}
                              >
                                Alfa Slab One
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Baloo Tammudu 2, cursive"
                                onClick={this.set_font.bind(
                                  this,
                                  "Baloo Tammudu 2"
                                )}
                                style={{
                                  fontFamily: "Baloo Tammudu 2, cursive",
                                }}
                              >
                                Baloo Tammudu 2
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Berkshire Swash, cursive"
                                onClick={this.set_font.bind(
                                  this,
                                  "Berkshire Swash"
                                )}
                                style={{
                                  fontFamily: "Berkshire Swash, cursive",
                                }}
                              >
                                Berkshire Swash
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Courier Prime, monospace"
                                onClick={this.set_font.bind(
                                  this,
                                  "Courier Prime"
                                )}
                                style={{
                                  fontFamily: "Courier Prime, monospace",
                                }}
                              >
                                Courier Prime
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Faster One, cursive"
                                onClick={this.set_font.bind(this, "Faster One")}
                                style={{ fontFamily: "Faster One, cursive" }}
                              >
                                Faster One
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Fira Sans, sans-serif"
                                onClick={this.set_font.bind(this, "Fira Sans")}
                                style={{ fontFamily: "Fira Sans, sans-serif" }}
                              >
                                Fira Sans
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Fredoka One, cursive"
                                onClick={this.set_font.bind(
                                  this,
                                  "Fredoka One"
                                )}
                                style={{ fontFamily: "Fredoka One, cursive" }}
                              >
                                Fredoka One
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Gloria Hallelujah, cursive"
                                onClick={this.set_font.bind(
                                  this,
                                  "Gloria Hallelujah"
                                )}
                                style={{
                                  fontFamily: "Gloria Hallelujah, cursive",
                                }}
                              >
                                Gloria Hallelujah
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Hanalei Fill, cursive"
                                onClick={this.set_font.bind(
                                  this,
                                  "Hanalei Fill"
                                )}
                                style={{ fontFamily: "Hanalei Fill, cursive" }}
                              >
                                Hanalei Fill
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Julius Sans One, sans-serif"
                                onClick={this.set_font.bind(
                                  this,
                                  "Julius Sans One"
                                )}
                                style={{
                                  fontFamily: "Julius Sans One, sans-serif",
                                }}
                              >
                                Julius Sans One
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Lobster, cursive"
                                onClick={this.set_font.bind(this, "Lobster")}
                                style={{ fontFamily: "Lobster, cursive" }}
                              >
                                Lobster
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Montserrat, sans-serif"
                                onClick={this.set_font.bind(this, "Montserrat")}
                                style={{ fontFamily: "Montserrat, sans-serif" }}
                              >
                                Montserrat
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Mulish, sans-serif"
                                onClick={this.set_font.bind(this, "Mulish")}
                                style={{ fontFamily: "Mulish, sans-serif" }}
                              >
                                Mulish
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Pacifico, cursive"
                                onClick={this.set_font.bind(this, "Pacifico")}
                                style={{ fontFamily: "Pacifico, cursive" }}
                              >
                                Pacifico
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Piedra', cursive"
                                onClick={this.set_font.bind(this, "Piedra")}
                                style={{ fontFamily: "Piedra', cursive" }}
                              >
                                Piedra
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Recursive, sans-serif"
                                onClick={this.set_font.bind(this, "Recursive")}
                                style={{ fontFamily: "Recursive, sans-serif" }}
                              >
                                Recursive
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Righteous, cursive"
                                onClick={this.set_font.bind(this, "Righteous")}
                                style={{ fontFamily: "Righteous, cursive" }}
                              >
                                Righteous
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Sacramento, cursive"
                                onClick={this.set_font.bind(this, "Sacramento")}
                                style={{ fontFamily: "Sacramento, cursive" }}
                              >
                                Sacramento
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Roboto, sans-serif"
                                onClick={this.set_font.bind(this, "Roboto")}
                                style={{ fontFamily: "Roboto, sans-serif" }}
                              >
                                Roboto
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Shadows Into Light, cursive"
                                onClick={this.set_font.bind(
                                  this,
                                  "Shadows Into Light"
                                )}
                                style={{
                                  fontFamily: "Shadows Into Light, cursive",
                                }}
                              >
                                Shadows Into Light
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <label id="start_qr" htmlFor="validationServer01">
                            Text Alignment
                          </label>
                          <br />
                          <button
                            className={
                              textAlign === "left"
                                ? "btn btn-sm btn-primary mr-1"
                                : "btn btn-sm btn-outline-primary mr-1"
                            }
                            onClick={() =>
                              this.setState({
                                textAlign: "left",
                              })
                            }
                          >
                            <Icon.AlignLeft size={24} />
                          </button>
                          <button
                            className={
                              textAlign === "center"
                                ? "btn btn-sm btn-primary mr-1"
                                : "btn btn-sm btn-outline-primary mr-1"
                            }
                            onClick={() =>
                              this.setState({
                                textAlign: "center",
                              })
                            }
                          >
                            <Icon.AlignCenter size={24} />
                          </button>
                          <button
                            className={
                              textAlign === "flex-end"
                                ? "btn btn-sm btn-primary mr-1"
                                : "btn btn-sm btn-outline-primary mr-1"
                            }
                            onClick={() =>
                              this.setState({
                                textAlign: "flex-end",
                              })
                            }
                          >
                            <Icon.AlignRight size={24} />
                          </button>
                        </div>
                        <div className="col-auto">
                          <label id="start_qr" htmlFor="validationServer01">
                            Font Size : {this.state.font_size + " px"}
                            <i class="fe fe-type"></i>{" "}
                            <span id="newValue" value="0"></span>
                          </label>
                          <br />
                          <input
                            type="range"
                            min="15"
                            step="1"
                            max="75"
                            class="form-control-range"
                            id="wwwrange"
                            value={this.state.font_size}
                            style={{ marginTop: "8px" }}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-auto">
                          <label htmlFor="validationServer03">Bring to</label>
                          <br />
                          <button
                            id="draggable_text_center"
                            class="btn btn-sm btn-outline-primary"
                            onClick={this.text_center}
                          >
                            {this.state.center_text}
                          </button>
                        </div>
                        <div className="col-auto">
                          {/* <label for="stick_to_center">Auto stick to center</label> &nbsp;
                      <input type="checkbox" id="stick_to_center" onChange={this.text_auto_stick}></input> */}
                          Center align <br />
                          {this.state.center_lock_text ? (
                            <button
                              className="btn btn-outline-primary btn-sm"
                              onClick={this.text_center_unlock}
                            >
                              <Icon.Lock size={24} /> Locked
                            </button>
                          ) : (
                            <button
                              className="btn btn-outline-primary btn-sm"
                              onClick={this.text_center_lock}
                            >
                              <Icon.Unlock size={24} /> Unlocked
                            </button>
                          )}
                        </div>
                        <div className="col-auto">
                          <label htmlFor="long_text">Test Long Name</label>
                          <br />
                          &nbsp;
                          <input
                            className="form-control-sm"
                            type="text"
                            id="long_text"
                            placeholder="Enter a name"
                            onChange={this.long_name}
                            style={{ height: "31px", border: "1px solid" }}
                          ></input>
                        </div>
                        <div className="col-auto">
                          <div>
                            <label>Choose font color</label> <br />
                            <label
                              className="color-selector"
                              style={{
                                border: "1px solid " + this.state.color,
                              }}
                            >
                              <span
                                className="rectangle"
                                style={{ background: this.state.color }}
                              />
                              {/* <span>{this.state.color}</span> */}
                              <input
                                type="color"
                                value={this.state.color}
                                onChange={this.handleColorChange}
                                className="hidden"
                              />
                            </label>
                            {/* <input
                              type="color"
                              value={this.state.color}
                              onChange={this.handleColorChange}
                              className="hidden"
                            /> */}
                          </div>
                        </div>
                      </div>

                      {/* <div className="row">
                        <div className="col-12">
                          <p> {"(Width : "+this.state.text_width} 
                          {") (Height : "+this.state.text_height} 
                          {") (X Cord : "+this.state.text_x} 
                          {") (Y Cord : "+this.state.text_y}
                          {") (Font : "+this.state.font_face}
                          {") (Font Size : "+this.state.value+")"}   </p>
                        </div>
                      </div> */}
                    </div>
                    <div
                      role="tabpanel"
                      className={this.state.tab2_class}
                      id="tab2"
                    >
                      <div className="row g-4 justify-content-center">
                        <div className="col-auto">
                          <label htmlFor="validationServer03">Bring to</label>{" "}
                          <br />
                          <button
                            id="draggable_qr_center"
                            class="btn btn-sm btn-outline-primary"
                            onClick={this.qr_center}
                          >
                            {this.state.center_text}
                          </button>
                        </div>
                        <div className="col-auto">
                          {/* <label for="stick_to_center">Auto stick to center</label> &nbsp;
                      <input type="checkbox" id="stick_to_center" onChange={this.text_auto_stick}></input> */}
                          Center align <br />
                          {this.state.center_lock_qr ? (
                            <button
                              className="btn btn-outline-primary btn-sm"
                              onClick={this.qr_center_unlock}
                            >
                              <Icon.Lock size={24} /> Locked
                            </button>
                          ) : (
                            <button
                              className="btn btn-outline-primary btn-sm"
                              onClick={this.qr_center_lock}
                            >
                              <Icon.Unlock size={24} /> Unlocked
                            </button>
                          )}
                        </div>
                        <div className="col-auto">
                          <div>
                            <label>Choose front color</label> <br />
                            <label
                              className="color-selector"
                              style={{
                                border:
                                  "1px solid " + this.state.qr_front_color,
                                height: "31px",
                              }}
                            >
                              <span
                                className="rectangle"
                                style={{
                                  background: this.state.qr_front_color,
                                }}
                              />
                              {/* <span>{this.state.qr_front_color}</span> */}
                              <input
                                type="color"
                                name="qr_front_color"
                                value={this.state.qr_front_color}
                                onChange={this.handleQrColorChange}
                                className="hidden"
                              />
                            </label>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div>
                            <label>Choose background color</label> <br />
                            <label
                              className="color-selector"
                              style={{
                                border: "1px solid " + this.state.qr_back_color,
                              }}
                            >
                              <span
                                className="rectangle"
                                style={{ background: this.state.qr_back_color }}
                              />
                              {/* <span>{this.state.qr_back_color}</span> */}
                              <input
                                type="color"
                                name="qr_back_color"
                                value={this.state.qr_back_color}
                                onChange={this.handleQrColorChange}
                                className="hidden"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-12">
                          <p> {"(Width : "+this.state.qr_width} 
                          {") (Height : "+this.state.qr_height} 
                          {") (X Cord : "+this.state.qr_x} 
                          {") (Y Cord : "+this.state.qr_y+")"} </p>
                        </div>
                      </div> */}
                    </div>

                    <div
                      role="tabpanel"
                      className={this.state.tab3_class}
                      id="tab3"
                    >
                      <div className="row g-4 justify-content-center">
                        <div className="col-md-auto">
                          <label htmlFor="">Choose Font Family</label> <br />
                          <div class="btn-group dropdown">
                            <button
                              type="button"
                              class="btn btn-primary dropdown-toggle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              id="choose_font_text"
                              aria-expanded="false"
                              style={{
                                fontFamily: this.state.font_face,
                                height: "31px",
                                paddingTop: "3px",
                              }}
                            >
                              {this.state.font_text}
                            </button>
                            <div class="dropdown-menu" id="select_font">
                              <button
                                class="dropdown-item"
                                type="button"
                                // value=""
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Avenir"
                                )}
                                value="avenir, arial, helvetica, sans-serif"
                                style={{
                                  fontFamily:
                                    "avenir, arial, helvetica, sans-serif",
                                }}
                              >
                                Avenir
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="andale mono, times"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Andale Mono"
                                )}
                                style={{ fontFamily: "andale mono, times" }}
                              >
                                Andale Mono
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="arial, helvetica, sans-serif"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Arial"
                                )}
                                style={{
                                  fontFamily: "arial, helvetica, sans-serif",
                                }}
                              >
                                Arial
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="arial black, avant garde"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Arial Black"
                                )}
                                style={{
                                  fontFamily: "arial black, avant garde",
                                }}
                              >
                                Arial Black
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="book antiqua, palatino"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Book Antiqua"
                                )}
                                style={{ fontFamily: "book antiqua, palatino" }}
                              >
                                Book Antiqua
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="comic sans ms, sans-serif"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Comic Sans MS"
                                )}
                                style={{
                                  fontFamily: "comic sans ms, sans-serif",
                                }}
                              >
                                Comic Sans MS
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="courier new, courier"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Courier New"
                                )}
                                style={{ fontFamily: "courier new, courier" }}
                              >
                                Courier New
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="georgia, palatino"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Georgia"
                                )}
                                style={{ fontFamily: "georgia, palatino" }}
                              >
                                Georgia
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="helvetica"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Helvetica"
                                )}
                                style={{ fontFamily: "helvetica" }}
                              >
                                Helvetica
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="impact, chicago"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Impact"
                                )}
                                style={{ fontFamily: "impact, chicago" }}
                                // style={{ fontFamily: "" }}
                              >
                                Impact
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="tahoma, arial, helvetica, sans-serif"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Tahoma"
                                )}
                                style={{
                                  fontFamily:
                                    "tahoma, arial, helvetica, sans-serif",
                                }}
                              >
                                Tahoma
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="terminal, monaco"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Terminal"
                                )}
                                style={{ fontFamily: "terminal, monaco" }}
                              >
                                Terminal
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="times new roman, times"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Times New Roman"
                                )}
                                style={{ fontFamily: "times new roman, times" }}
                              >
                                Times New Roman
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="trebuchet ms, geneva"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Trebuchet MS"
                                )}
                                style={{ fontFamily: "trebuchet ms, geneva" }}
                              >
                                Trebuchet MS
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="verdana, geneva"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Verdana"
                                )}
                                style={{ fontFamily: "verdana, geneva" }}
                              >
                                Verdana
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Aclonica, sans-serif"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Aclonica"
                                )}
                                style={{ fontFamily: "Aclonica, sans-serif" }}
                              >
                                Aclonica
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Alfa Slab One, cursive"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Alfa Slab One"
                                )}
                                style={{ fontFamily: "Alfa Slab One, cursive" }}
                              >
                                Alfa Slab One
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Baloo Tammudu 2, cursive"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Baloo Tammudu 2"
                                )}
                                style={{
                                  fontFamily: "Baloo Tammudu 2, cursive",
                                }}
                              >
                                Baloo Tammudu 2
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Berkshire Swash, cursive"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Berkshire Swash"
                                )}
                                style={{
                                  fontFamily: "Berkshire Swash, cursive",
                                }}
                              >
                                Berkshire Swash
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Courier Prime, monospace"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Courier Prime"
                                )}
                                style={{
                                  fontFamily: "Courier Prime, monospace",
                                }}
                              >
                                Courier Prime
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Faster One, cursive"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Faster One"
                                )}
                                style={{ fontFamily: "Faster One, cursive" }}
                              >
                                Faster One
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Fira Sans, sans-serif"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Fira Sans"
                                )}
                                style={{ fontFamily: "Fira Sans, sans-serif" }}
                              >
                                Fira Sans
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Fredoka One, cursive"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Fredoka One"
                                )}
                                style={{ fontFamily: "Fredoka One, cursive" }}
                              >
                                Fredoka One
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Gloria Hallelujah, cursive"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Gloria Hallelujah"
                                )}
                                style={{
                                  fontFamily: "Gloria Hallelujah, cursive",
                                }}
                              >
                                Gloria Hallelujah
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Hanalei Fill, cursive"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Hanalei Fill"
                                )}
                                style={{ fontFamily: "Hanalei Fill, cursive" }}
                              >
                                Hanalei Fill
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Julius Sans One, sans-serif"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Julius Sans One"
                                )}
                                style={{
                                  fontFamily: "Julius Sans One, sans-serif",
                                }}
                              >
                                Julius Sans One
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Lobster, cursive"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Lobster"
                                )}
                                style={{ fontFamily: "Lobster, cursive" }}
                              >
                                Lobster
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Montserrat, sans-serif"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Montserrat"
                                )}
                                style={{ fontFamily: "Montserrat, sans-serif" }}
                              >
                                Montserrat
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Mulish, sans-serif"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Mulish"
                                )}
                                style={{ fontFamily: "Mulish, sans-serif" }}
                              >
                                Mulish
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Pacifico, cursive"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Pacifico"
                                )}
                                style={{ fontFamily: "Pacifico, cursive" }}
                              >
                                Pacifico
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Piedra', cursive"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Piedra"
                                )}
                                style={{ fontFamily: "Piedra', cursive" }}
                              >
                                Piedra
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Recursive, sans-serif"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Recursive"
                                )}
                                style={{ fontFamily: "Recursive, sans-serif" }}
                              >
                                Recursive
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Righteous, cursive"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Righteous"
                                )}
                                style={{ fontFamily: "Righteous, cursive" }}
                              >
                                Righteous
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Sacramento, cursive"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Sacramento"
                                )}
                                style={{ fontFamily: "Sacramento, cursive" }}
                              >
                                Sacramento
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Roboto, sans-serif"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Roboto"
                                )}
                                style={{ fontFamily: "Roboto, sans-serif" }}
                              >
                                Roboto
                              </button>
                              <button
                                class="dropdown-item"
                                type="button"
                                value="Shadows Into Light, cursive"
                                onClick={this.setExtraFieldsFont.bind(
                                  this,
                                  "Shadows Into Light"
                                )}
                                style={{
                                  fontFamily: "Shadows Into Light, cursive",
                                }}
                              >
                                Shadows Into Light
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <label id="start_qr" htmlFor="validationServer01">
                            Text Alignment
                          </label>
                          <br />
                          <button
                            className="btn btn-sm btn-outline-primary mr-1"
                            onClick={() => this.setExtraFieldsAlign("left")}
                          >
                            <Icon.AlignLeft size={24} />
                          </button>
                          <button
                            className="btn btn-sm btn-outline-primary mr-1"
                            onClick={() => this.setExtraFieldsAlign("center")}
                          >
                            <Icon.AlignCenter size={24} />
                          </button>
                          <button
                            className="btn btn-sm btn-outline-primary mr-1"
                            onClick={() => this.setExtraFieldsAlign("flex-end")}
                          >
                            <Icon.AlignRight size={24} />
                          </button>
                        </div>
                        <div className="col-auto">
                          <label id="start_qr" htmlFor="validationServer01">
                            Font Size : {this.state.fontSize + " px"}
                            <i class="fe fe-type"></i>{" "}
                            <span id="newValue" value="0"></span>
                          </label>
                          <br />
                          <input
                            type="range"
                            min="15"
                            step="1"
                            max="75"
                            class="form-control-range"
                            id="wwwrange"
                            style={{ marginTop: "8px" }}
                            onChange={this.setExtraFieldsFontSize}
                          />
                        </div>
                        <div className="col-auto">
                          <div>
                            <label>Choose font color</label> <br />
                            <label
                              className="color-selector"
                              style={{
                                border: "1px solid " + this.state.fontColor,
                              }}
                            >
                              <span
                                className="rectangle"
                                style={{ background: this.state.fontColor }}
                              />
                              <input
                                type="color"
                                value={this.state.fontColor}
                                onChange={this.setExtraFieldsColor}
                                className="hidden"
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.template_selection_view ? (
            <div className="card mt-4  p-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3 d-flex">
                    <div className="card mt-4 lift shadow-sm border ">
                      <div className="card-body row justify-content-center h-100">
                        <img
                          id="img"
                          src="../../../../assets/img/add_blue.png"
                          style={{ height: "auto", width: 180 }}
                          alt="addsuffix"
                          onClick={this.loadCanvaSDK}
                        />
                        <p className="font-weight-bold text-center">
                          Create New Template
                        </p>
                        <p></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 d-flex">
                    <div className="card mt-4 lift shadow-sm border ">
                      <div className="card-body row justify-content-center h-100">
                        <img
                          id="img"
                          src="../../../../assets/img/add_blue.png"
                          style={{ height: "auto", width: 180 }}
                          alt="addsuffix"
                          class=""
                          data-toggle="modal"
                          data-target="#upload_template"
                        />
                        <p className="font-weight-bold text-center">
                          Upload Template{" "}
                        </p>
                        <p></p>
                      </div>
                    </div>
                  </div>
                  {template_data.map((data, index) => (
                    <div className="col-md-3 d-flex" key={index}>
                      <div className="card mt-4 lift shadow-sm border ">
                        <div className="card-body row">
                          <img
                            className="img-fluid"
                            src={data.template_img}
                            alt=""
                          />
                          <div className="mt-3">
                            <button
                              type="button"
                              class="btn btn-primary btn-block"
                              onClick={this.selectTemplate.bind(
                                this,
                                data.template_id,
                                data.template_img
                              )}
                            >
                              Select
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {this.state.next_url ? (
                    <div className="col-12 text-center mt-5">
                      <button
                        className="btn btn-white btn-sm rounded-pill border px-3"
                        onClick={this.paginationData.bind(
                          this,
                          this.state.next_url
                        )}
                      >
                        Show more{" "}
                        {this.state.show_more ? (
                          <ClassicSpinner
                            size={13}
                            className="d-inline-block"
                            color={
                              localStorage.getItem("theme-mode") === "dark"
                                ? "#b5b5b7"
                                : "#686769"
                            }
                            loading={true}
                          />
                        ) : (
                          ""
                        )}
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="card shadow-sm">
              <div
                id="show_cert_design"
                className="mx-auto mb-5"
                style={{
                  display: "inline-block",
                  position: "relative",
                  marginTop: "50px",
                }}
              >
                <img
                  id="img"
                  src={this.state.template_img}
                  alt="addsuffix"
                  class=""
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "auto",
                  }}
                  draggable="false"
                  ref={(templateElement) => {
                    this.templateElement = templateElement;
                  }}
                />

                <Rnd
                  default={{
                    x: this.state.text_x,
                    y: this.state.text_y,
                    width: this.state.text_width,
                    height: this.state.text_height,
                  }}
                  ref={(c) => {
                    this.rnd = c;
                  }}
                  style={style}
                  minWidth={300}
                  minHeight={80}
                  maxWidth={800}
                  maxHeight={200}
                  bounds={"parent"}
                  onResize={(e, direction, ref, delta, position) => {
                    this.setState({
                      text_width: ref.offsetWidth,
                      text_height: ref.offsetHeight,
                    });
                  }}
                  position={{ x: this.state.text_x, y: this.state.text_y }}
                  onDragStop={(e, d) => {
                    this.state.text_auto_stick_enabled
                      ? this.setState({ text_y: d.y })
                      : this.setState({ text_x: d.x, text_y: d.y });
                  }}
                  onDragStart={() => {
                    this.tab1_active();
                  }}
                  dragAxis={this.state.text_auto_stick_enabled ? "y" : "both"}
                >
                  <span
                    id="d_text"
                    style={font}
                    ref={(textElement) => {
                      this.textElement = textElement;
                    }}
                  >
                    {this.state.test_name}{" "}
                  </span>
                </Rnd>

                {extraFields.map((ef, index) => (
                  <Rnd
                    default={{
                      x: ef.x,
                      y: ef.y,
                      width: ef.w,
                      height: ef.h,
                    }}
                    ref={(c) => {
                      this.rnd = c;
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: ef.textAlign,
                      border:
                        activeIndex === index
                          ? "solid 2px red"
                          : "solid 1px #2c7be5",
                    }}
                    bounds={"parent"}
                    onResize={(e, direction, ref, delta, position) =>
                      this.setExtraFieldsWH(ref, position, index)
                    }
                    position={{ x: ef.x, y: ef.y }}
                    onDragStop={(e, d) =>
                      this.setExtraFieldsXY(d.x, d.y, index)
                    }
                    onDragStart={() => {
                      this.tab3_active();
                    }}
                    onMouseDown={() => this.setActiveIndex(index)}
                    dragAxis={this.state.text_auto_stick_enabled ? "y" : "both"}
                  >
                    <span
                      id="d_text"
                      style={{
                        fontSize: ef.fontSize + "px",
                        fontFamily: ef.fontFamily,
                        color: ef.fontColor,
                      }}
                      ref={(textElement) => {
                        this.textElement = textElement;
                      }}
                    >
                      Field - {index + 1}
                    </span>
                  </Rnd>
                ))}

                <Rnd
                  ref={(c) => {
                    this.rnd = c;
                  }}
                  default={{
                    x: this.state.qr_x,
                    y: this.state.qr_y,
                    width: this.state.qr_width,
                    height: this.state.qr_height,
                  }}
                  style={qr_style}
                  minWidth={100}
                  minHeight={100}
                  maxWidth={300}
                  maxHeight={300}
                  lockAspectRatio={true}
                  bounds={"parent"}
                  onResize={(e, direction, ref, delta, position) => {
                    this.setState({
                      qr_width: ref.offsetWidth,
                      qr_height: ref.offsetHeight,
                    });
                  }}
                  position={{ x: this.state.qr_x, y: this.state.qr_y }}
                  onDragStop={(e, d) => {
                    this.state.qr_auto_stick_enabled
                      ? this.setState({ qr_y: d.y })
                      : this.setState({ qr_x: d.x, qr_y: d.y });
                  }}
                  dragAxis={this.state.qr_auto_stick_enabled ? "y" : "both"}
                  onDragStart={() => {
                    this.tab2_active();
                  }}
                >
                  {/* <img id="qrimg" draggable="false"
              src={`${process.env.PUBLIC_URL}/assets/img/qr.svg`}
              alt="qr code" class="" style={{width:"100%", height:"100%"}} ref={ (qrElement) => { this.qrElement = qrElement } }/> */}

                  <QRCode
                    className="qr_code_svg"
                    id="123456"
                    value="xaknxankxnakxnkanxknxjsbxsb"
                    size="100%"
                    renderAs="svg"
                    bgColor={this.state.qr_back_color}
                    fgColor={this.state.qr_front_color}
                  />
                </Rnd>
              </div>
            </div>
          )}

          {/* Template Upload Modal */}
          <Modal
            key="upload_template"
            id="upload_template"
            close_id="temp_upload"
            title="Upload Template"
          >
            <div class="alert alert-warning text-center" role="alert">
              <small className="text-dark text-center">
                {" "}
                The Certificate Design you upload should satisfy some
                requirements.{" "}
              </small>
              <small className="text-dark text-center">
                {" "}
                Please refer to{" "}
                <Link
                  rel="noreferrer noopener"
                  target="_blank"
                  to={"/cert-design-guide"}
                >
                  Certificate Design Guide
                </Link>
                &nbsp; or{" "}
                <Link
                  rel="noreferrer noopener"
                  target="_blank"
                  download="certifidoc-sample-cert.png"
                  to={require("../../img/sample-cert.png")}
                >
                  Download a Sample Certificate Design
                </Link>{" "}
                to Test Certifidoc.
              </small>
            </div>
            <form onSubmit={this.upload_template}>
              <div className="modal-body p-3">
                <div className="form-group">
                  <div>
                    <img
                      id="user_avtar"
                      src="../../../../assets/img/certificate_template/no_template.png"
                      alt="..."
                      className="avatar-img"
                      style={{ width: 450, height: "auto" }}
                    />
                  </div>
                  {"\u00A0"}
                  {"\u00A0"}
                  <button
                    className="btn btn-primary btn-sm mt-2"
                    type="button"
                    onClick={this.getProfile}
                  >
                    Choose Template
                  </button>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={this.setProfile}
                    className="d-none"
                    id="input_file"
                  />
                </div>
              </div>
              <div className="modal-footer border-0 pb-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm rounded-pill pl-3 pr-3"
                >
                  Upload Template{" "}
                  {this.state.spinner ? (
                    <ClassicSpinner
                      size={13}
                      className="d-inline-block"
                      color="#fff"
                      loading={true}
                    />
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </form>
          </Modal>
          <ToastContainer />
        </main>
      ) : (
        <main className="container">
          <div class="se-pre-con"></div>
        </main>
      )
    );
  }

  setProfile = (event) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      $("#user_avtar").attr("src", e.target.result);
    };
    reader.readAsDataURL(event.target.files[0]);
    this.setState({ template_img_path: event.target.files[0] });
    this.setState({ img_change: true });
  };
  getProfile = () => {
    document.getElementById("input_file").click();
  };
}
