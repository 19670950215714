import React, { PureComponent } from "react";

export default class Temp extends PureComponent {
  componentDidMount() {
    window.location.href =
      "/certificate/" + this.props.match.params.credential_id;
  }
  render() {
    return <div></div>;
  }
}
