import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { ClassicSpinner } from "react-spinners-kit";
import axios from "axios";
import Server from "../../routes/server";
import Skeleton from "react-loading-skeleton";
import { Award } from "react-feather";
import { server } from "../../../env";

export default class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      requesting: true,
      dashboard_data: [],
    };
  }
  componentDidMount() {
    Server.Session_check();
    this.getData();
  }
  getData = () => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(server + "/dashboard/data", config)
      .then((rsp) => {
        this.setState({
          dashboard_data: rsp.data,
          requesting: false,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({
            requesting: false,
          });
        }
      });
  };
  render() {
    const { dashboard_data } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4 shadow-sm pl-3">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Dashboard</h3>
          </div>
        </div>

        <div className="card mt-5 px-3">
          <div className="card-body">
            <img
              className="img-fluid d-none d-md-block float-left"
              width="28%"
              src="/assets/img/welcome.svg"
              alt=""
            />

            <img
              className="img-fluid d-none d-md-block float-right"
              width="28%"
              src="/assets/img/welcome-scan.svg"
              alt=""
            />
            <div className="text-center mt-4">
              <h3 className="mb-4">
                {this.state.requesting ? (
                  <Skeleton width="30%" />
                ) : (
                  "Hi, " + dashboard_data.user_name
                )}
              </h3>
              <h4 className="card-title">Welcome to Certifidoc Dashboard</h4>
              {this.state.requesting ? (
                <Skeleton width="30%" />
              ) : dashboard_data.is_verified ? (
                ""
              ) : (
                <p className="card-text text-danger">
                  You've not verified account yet{" "}
                  <Link to="/contact" className="text-decoration-none">
                    contact us
                  </Link>{" "}
                  to verify.
                </p>
              )}

              <p className="card-text">
                Explore and learn how to use Certifidoc for publishing
                certificates.
              </p>

              <p className="card-text ">
                You can Choose the following Actions for starting out
              </p>
              <Link to="/help" className="btn btn-success mr-3">
                How to use Guide
              </Link>

              <Link to="/faqs" className="btn btn-primary">
                FAQs
              </Link>
              <div className="mt-3">
                <Link to="/cert-design-guide" className="btn btn-dark mr-3">
                  Certificate Design Guide
                </Link>
              </div>
            </div>
          </div>
          <div className="card-footer border-0 bg-transparent text-center text-muted">
            <small>
              Powered by{" "}
              <a
                href="https://wilyer.com"
                rel="dofollow noopener noreferrer"
                target="_blank"
                className="text-decoration-none"
              >
                Wilyer
              </a>
            </small>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Link to="/events" className="text-decoration-none">
              <div className="card lift px-3">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <p className="text-uppercase text-muted mb-2 font-weight-bold">
                        Total Published Events
                      </p>
                      <span className="h3 mb-0">
                        {this.state.requesting ? (
                          <ClassicSpinner
                            size={13}
                            className="d-inline-block"
                            color="#000000"
                            loading={true}
                          />
                        ) : (
                          dashboard_data.publish_event
                        )}
                      </span>
                    </div>
                    <div className="col-auto mr-4">
                      <Award />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-6">
            <Link to="/events" className="text-decoration-none">
              <div className="card lift px-3">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col">
                      <p className="text-uppercase text-muted mb-2 font-weight-bold">
                        Total Unpublished Events
                      </p>
                      <span className="h3 mb-0">
                        {this.state.requesting ? (
                          <ClassicSpinner
                            size={13}
                            className="d-inline-block"
                            color="#000000"
                            loading={true}
                          />
                        ) : (
                          dashboard_data.unpublish_event
                        )}
                      </span>
                    </div>
                    <div className="col-auto mr-4">
                      <Award />
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-6">
            <div className="card lift px-3">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <p className="text-uppercase text-muted mb-2 font-weight-bold">
                      Total Published Credentials
                    </p>
                    <span className="h3 mb-0">
                      {this.state.requesting ? (
                        <ClassicSpinner
                          size={13}
                          className="d-inline-block"
                          color="#000000"
                          loading={true}
                        />
                      ) : (
                        dashboard_data.publish_credential
                      )}
                    </span>
                  </div>
                  <div className="col-auto mr-4">
                    <Award />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card lift px-3">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <p className="text-uppercase text-muted mb-2 font-weight-bold">
                      Total Unpublished Credentials
                    </p>
                    <span className="h3 mb-0">
                      {this.state.requesting ? (
                        <ClassicSpinner
                          size={13}
                          className="d-inline-block"
                          color="#000000"
                          loading={true}
                        />
                      ) : (
                        dashboard_data.unpublish_credential
                      )}
                    </span>
                  </div>
                  <div className="col-auto mr-4">
                    <Award />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
