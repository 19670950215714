import React, { PureComponent } from "react";
import { ClassicSpinner } from "react-spinners-kit";
import { ArrowLeft, ArrowRight, Trash2, Plus } from "react-feather";
import { ExcelRenderer } from "react-excel-renderer";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import axios from "axios";
import Modal from "../../components/modal";
import Server from "../../routes/server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { server } from "../../../env";

export default class AddCredential extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      event_id: this.props.match.params.event_id,
      tableData: [],
      dataPerPage: 500,
      currentPageNumber: 1,
      checked: true,
      unchecked: false,
      loading: false,
      duplicate_msg: "",
      duplicateClass: "d-none",
      btn_block: true,
      error_resp: [],
      is_event_publish: false,
      is_saving: false,
      send_email: true,
    };
  }
  componentDidMount() {
    Server.Session_check();
    this.get_data();
  }

  get_data = () => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(server + "/event/event-details/" + this.state.event_id, config)
      .then((rsp) => {
        this.setState({
          is_event_publish: rsp.data.publish,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          // this.setState({
          //   requesting: false,
          // });
        }
      });
  };

  // handle click event of the Add button
  handleAddClick = (e) => {
    e.preventDefault();
    this.setState({
      tableData: [
        {
          name: "Name",
          email: "example@gmail.com",
          mobile_no: "97XXXXXXXX",
          extraFields: "",
          issue_date: new Date().toLocaleDateString("fr-CA"),
          event_id: this.state.event_id,
        },
        ...this.state.tableData,
      ],
      btn_block: false,
    });
  };

  // File handle
  fileHandler = (event) => {
    let fileObj = event.target.files[0];
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        // Check if excel data has more than 500 value
        if (resp.rows.length >= 1 && resp.rows.length <= 501) {
          this.setState({
            cols: resp.cols,
            rows: resp.rows,
          });
          let data = [];
          let curr_date = new Date();
          var days_since;
          for (let i = 1; i < resp.rows.length; i++) {
            if (
              resp.rows[i][3] == null ||
              resp.rows[i][3] === "" ||
              resp.rows[i][3] === undefined ||
              typeof resp.rows[i][3] == "string"
            ) {
              days_since = (
                25567.0 +
                (curr_date.getTime() -
                  curr_date.getTimezoneOffset() * 60 * 1000) /
                  (1000 * 60 * 60 * 24)
              )
                .toString()
                .substr(0, 20);
            } else {
              days_since = resp.rows[i][3] - 1;
            }
            if (resp.rows[i][2] === undefined) {
              resp.rows[i][2] = "";
            }
            if (
              resp.rows[i][1] === undefined ||
              resp.rows[i][1] === "" ||
              resp.rows[i][1] == null
            ) {
              continue;
            }

            const newData = {
              name: resp.rows[i][0],
              email: resp.rows[i][1],
              mobile_no: resp.rows[i][2],
              extraFields: [
                resp.rows[i][4],
                resp.rows[i][5],
                resp.rows[i][6],
                resp.rows[i][7],
                resp.rows[i][8],
                resp.rows[i][9],
                resp.rows[i][10],
              ].join(","),
              issue_date: new Date(
                (days_since - (25567 + 1)) * 86400 * 1000
              ).toLocaleDateString("fr-CA"),
              event_id: this.state.event_id,
            };
            data = [...data, newData];
          }
          this.setState({
            tableData: [...this.state.tableData, ...data],
            btn_block: false,
          });
        } else {
          // this.setDate({duplicate_msg:"Maximum 500 credentials can be imported at a time.",duplicateClass:"alert alert-danger rounded-lg alert-dismissible"})
          this.notify(
            "Maximum 500 credentials can be imported at a time.",
            "error"
          );
        }
      }
    });
  };

  handleEnter = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };
  handleNextPagination = (e) => {
    e.preventDefault();
    this.setState({
      currentPageNumber: this.state.currentPageNumber + 1,
    });
  };
  handlePrevPagination = (e) => {
    e.preventDefault();
    this.setState({
      currentPageNumber: this.state.currentPageNumber - 1,
    });
  };

  handleDeleteRow = (index, e) => {
    e.preventDefault();
    const list = [...this.state.tableData];
    list.splice(
      this.state.dataPerPage * this.state.currentPageNumber -
        (this.state.dataPerPage - index),
      1
    );
    this.setState({ tableData: list });
    if (list.length === 0) {
      this.setState({ btn_block: true });
    }
    //Delete from error array
    if (this.state.error_resp.length === 0) {
    } else {
      const arr = [...this.state.error_resp];
      arr.splice(
        this.state.dataPerPage * this.state.currentPageNumber -
          (this.state.dataPerPage - index),
        1
      );
      this.setState({ error_resp: arr });
    }
  };
  setDate = (index, name, date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    var converted_date = year + "-" + month + "-" + day;
    const list = [...this.state.tableData];
    list[
      this.state.dataPerPage * this.state.currentPageNumber -
        (this.state.dataPerPage - index)
    ][name] = converted_date;
    this.setState({ tableData: list });
  };

  handleEdit = (index, e) => {
    const list = [...this.state.tableData];
    list[
      this.state.dataPerPage * this.state.currentPageNumber -
        (this.state.dataPerPage - index)
    ][e.target.name] = e.target.value;
    this.setState({ tableData: list });
  };
  handlecheckbox = (index, e) => {
    const list = [...this.state.tableData];
    list[
      this.state.dataPerPage * this.state.currentPageNumber -
        (this.state.dataPerPage - index)
    ][e.target.name] = String(e.target.checked);
    this.setState({ tableData: list });
  };

  save_cred = (e) => {
    if (this.state.tableData.length <= 500) {
      e.preventDefault();
      if (this.is_duplicate()) {
        // this.setState({duplicate_msg: "* Duplicate Email ID detected. An Event can not have same Email IDs for Recipients.Try Removing Duplicate Emails to Proceed.",duplicateClass:"alert alert-danger rounded-lg alert-dismissible"})
        this.notify(
          "Duplicate Email ID detected. An Event can not have same Email IDs for Recipients.Try Removing Duplicate Emails to Proceed.",
          "error"
        );
      } else {
        this.setState({ is_saving: true });
        const token = localStorage.getItem("footprint");
        const config = {
          headers: {
            Authorization: `Token ${token}`,
          },
        };
        axios
          .post(server + "/credential/create", this.state.tableData, config)
          .then((rsp) => {
            this.setState({ tableData: [], is_saving: false });
            this.props.history.push("/event-details/" + this.state.event_id);
            // this.state.setSubmitButton === "save" ? this.props.history.push('/events') : this.props.history.push('/certificate-editor/'+rsp.data.event_id)
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
              this.setState({
                error_resp: error.response.data,
                is_saving: false,
              });
              this.notify(
                "This Event already contains the recipients with red marked emails, Please Remove Redundant Emails to Proceed.",
                "error"
              );
              // this.setState({spinner:false})
            }
          });
      }
    } else {
      this.notify("Maximum 500 credentials can be added at a time.", "error");
    }
  };
  save_publish = (e) => {
    if (this.state.tableData.length <= 500) {
      if (this.is_duplicate()) {
        document.getElementById("save_publish_close").click();
        this.notify(
          "Duplicate Email ID detected. An Event can not have same Email IDs for Recipients.Try Removing Duplicate Emails to Proceed.",
          "error"
        );
      } else {
        this.setState({ loading: true });
        e.preventDefault();
        const token = localStorage.getItem("footprint");
        const config = {
          headers: {
            Authorization: `Token ${token}`,
          },
        };
        axios
          .post(
            server + "/credential/create/publish",
            this.state.tableData,
            config
          )
          .then((rsp) => {
            console.log(rsp);
            this.setState({ tableData: [], loading: false });
            document.getElementById("save_publish_close").click();
            this.props.history.push("/event-details/" + this.state.event_id);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
              this.setState({
                error_resp: error.response.data,
                loading: false,
              });
              this.notify(
                "This Data already contains some of Red Marked Emails. Please Remove Redundant Emails to Proceed.",
                "error"
              );
            }
          });
      }
    } else {
      this.notify("Maximum 500 credentials can be added at a time.", "error");
    }
  };
  getUniqueListBy = (arr, key) => {
    const arr2 = [...new Map(arr.map((item) => [item[key], item])).values()];

    if (arr.length === arr2.length) {
      return true;
    } else {
      return false;
    }
  };
  is_duplicate = () => {
    const arr1 = this.getUniqueListBy(this.state.tableData, "email");
    if (arr1) {
      return false;
    } else {
      return true;
    }
  };
  notify = (msg, toast_type) => {
    toast(msg, {
      type: toast_type,
      autoClose: 30000,
    });
  };
  selectOnFocus = (event) => event.target.select();
  render() {
    const { tableData, dataPerPage, currentPageNumber, error_resp } =
      this.state;
    const lastDataIndex = currentPageNumber * dataPerPage;
    const firstDataIndex = lastDataIndex - dataPerPage;
    const currentData = tableData.slice(firstDataIndex, lastDataIndex);
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3 mb-5">
        {this.state.event_id ? "" : this.props.history.push("/events")}
        <div className="card mt-4 shadow-sm">
          <div className="d-flex row p-3 align-items-center justify-content-between">
            <div className="col-md-4">
              <h3 className="card-title h5 mb-0">Add Credential</h3>
            </div>
            <div className="col-auto">
              {/* <span data-tip={this.state.is_event_publish ? this.state.btn_block ? "No credential found" : "" : "Publish the event before publishing credentials"} data-place="bottom">
            <button
                className="btn btn-success lift rounded-pill ml-1 px-3"
                data-toggle="modal"
                data-target="#save_and_publish"
                disabled={this.state.is_event_publish ? this.state.btn_block ? true : false : true}
              >
                Save and Publish
              </button>
              </span> */}
              <button
                className="btn btn-primary lift rounded-pill ml-1 px-3"
                onClick={this.save_cred}
                disabled={this.state.btn_block ? true : false}
              >
                Save
                {this.state.is_saving ? (
                  <ClassicSpinner
                    size={13}
                    className="d-inline-block"
                    color="#fff"
                    loading={true}
                  />
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="card mt-4 shadow-sm">
          <div className="card-header bg-transparent border-0 p-3">
            <div className="row justify-content-between ">
              <div className="col-md-8">
                <input
                  type="file"
                  id="docpicker"
                  class="btn btn-outline-primary"
                  onChange={this.fileHandler}
                  accept=".csv,application/vnd.ms-excel,.xlt,application/vnd.ms-excel,.xla,application/vnd.ms-excel,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xltx,application/vnd.openxmlformats-officedocument.spreadsheetml.template,.xlsm,application/vnd.ms-excel.sheet.macroEnabled.12,.xltm,application/vnd.ms-excel.template.macroEnabled.12,.xlam,application/vnd.ms-excel.addin.macroEnabled.12,.xlsb,application/vnd.ms-excel.sheet.binary.macroEnabled.12"
                />
                &nbsp;&nbsp;or &nbsp;&nbsp;
                <button
                  type="button"
                  onClick={this.handleAddClick}
                  class="btn btn-outline-primary"
                >
                  <Plus /> Add New Row
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h6 className="h6 text-muted small">
                  Upload excel file or{" "}
                  <a href="../../../../sample_file.xlsx" download>
                    Download sample file
                  </a>{" "}
                  for upload{" "}
                </h6>
              </div>
            </div>
          </div>
          <div className="card-body p-0 border-0">
            <div className="table-responsive px-3">
              <table className="table table-striped table-hover mb-0 border">
                <thead className="border">
                  <tr className="text-center">
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile No.</th>
                    <th>Extra Fields</th>
                    <th>Issue Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((x, index) => (
                    <tr
                      className={
                        error_resp.length < tableData.length ||
                        error_resp.length === 0
                          ? ""
                          : error_resp[index].hasOwnProperty("non_field_errors")
                          ? "duplicate"
                          : ""
                      }
                    >
                      <td>
                        <p className="mt-3">
                          {dataPerPage * currentPageNumber -
                            (dataPerPage - index) +
                            1}
                        </p>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="name"
                          className="form-control table-input rounded-lg mt-2 p-2"
                          value={x.name}
                          onChange={this.handleEdit.bind(this, index)}
                          onKeyUp={this.handleEnter}
                          onFocus={this.selectOnFocus}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="email"
                          className="form-control table-input rounded-lg mt-2 p-2"
                          value={x.email}
                          onChange={this.handleEdit.bind(this, index)}
                          onKeyUp={this.handleEnter}
                          onFocus={this.selectOnFocus}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="mobile_no"
                          className="form-control table-input rounded-lg mt-2 p-2"
                          value={x.mobile_no}
                          onChange={this.handleEdit.bind(this, index)}
                          onKeyUp={this.handleEnter}
                          onFocus={this.selectOnFocus}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="extraFields"
                          className="form-control table-input rounded-lg mt-2 p-2"
                          value={x.extraFields}
                          onChange={this.handleEdit.bind(this, index)}
                          onKeyUp={this.handleEnter}
                          onFocus={this.selectOnFocus}
                        />
                      </td>
                      <td>
                        <Flatpickr
                          value={x.issue_date}
                          defaultValue="Select Date"
                          className="form-control table-input rounded-lg mt-2 p-2"
                          onChange={(start_date) => {
                            this.setDate(index, "issue_date", start_date[0]);
                          }}
                        />
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-danger rounded-circle mt-2"
                          onClick={this.handleDeleteRow.bind(this, index)}
                        >
                          <Trash2 />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-footer bg-transparent border-0 p-3">
            <div className="row">
              <div className="col-6 text-muted">
                <h6 className="h6 pt-2 small">
                  Showing {currentData.length} of {this.state.tableData.length}
                </h6>
              </div>
              <div className="col-6 text-right">
                <button
                  className="btn btn-white btn-sm lift rounded-pill border px-3"
                  onClick={this.handlePrevPagination}
                  disabled={currentPageNumber === 1 ? true : false}
                >
                  Prev <ArrowLeft />
                </button>
                <button
                  className="btn btn-white btn-sm lift rounded-pill border px-3"
                  onClick={this.handleNextPagination}
                  disabled={
                    this.state.tableData.length === 0
                      ? true
                      : Math.ceil(tableData.length / dataPerPage) ===
                        currentPageNumber
                      ? true
                      : false
                  }
                >
                  Next <ArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          key="save_and_publish"
          id="save_and_publish"
          title="Save and Publish"
          close_id="save_publish_close"
        >
          <div className="modal-body text-center p-4 mb-3">
            <h5>Are you sure you want to publish all the credentials?</h5>

            <div className="mb-3 form-check mt-2 text-left">
              <input
                type="checkbox"
                className="form-check-input"
                name="send_email"
                checked={this.state.send_email ? true : false}
                onChange={() => {
                  this.setState({ send_email: !this.state.send_email });
                  console.log(this.state.send_email);
                }}
              />
              <label className="form-check-label" for="exampleCheck1">
                Send email notification to users.
              </label>
            </div>
            <div className="mt-4">
              <button
                className="btn btn-danger  rounded-pill rounded-lg"
                data-dismiss="modal"
              >
                No
              </button>
              <button
                className="btn btn-success rounded-pill ml-2"
                onClick={this.save_publish}
              >
                Yes{" "}
                {this.state.loading ? (
                  <ClassicSpinner
                    size={13}
                    className="d-inline-block"
                    color="#fff"
                    loading={true}
                  />
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </Modal>
        <ToastContainer autoClose={30000} />
        <ReactTooltip />
      </main>
    );
  }
}
