import React, { PureComponent } from 'react'
import { Copy,RefreshCcw, Settings } from 'react-feather';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class Dev_API extends PureComponent {
    state={
        is_key_generted:true,
        key:"nxakxnkanxkankxnaknxkankxankx"
    }
    notify=(msg,toast_type)=>{
        toast(msg,{
          type:toast_type
        })
      }
    copy=()=>{
        this.notify("API key has been copied","success")
      }
    render() {
        return (
            <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
                <div className="card mt-4 shadow-sm  pl-3">
                    <div className="d-flex justify-content-between p-3">
                        <h3 className="card-title h5 mb-0 mt-1 text-uppercase">Developer's API</h3>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body text-center">
                        <div className="col">
                            <h4 className="font-weight-bold color-grey">Developer's API</h4>
                        </div>
                        <div className="col mt-4">
                            <img style={{height:"80px"}} src="https://www.fast2sms.com/panel/api_key.svg" alt=""/>
                        </div>
                        <div className="col mt-3">
                            <h5 className="font-weight-bold color-grey">API Authorization Key</h5>
                        </div>
                        <div className="col mt-2">
                            <h7 className="color-grey">Created at: 15-01-2019 12:24:55 PM</h7>
                        </div>
                        <div className="col-6 mb-5" style={{margin:"auto"}}>
                            <div class="input-group">
                                <input type="text" class="form-control" value={this.state.key} placeholder="No API key generated" aria-label="API Key" aria-describedby="basic-addon2" disabled/>
                                <div class="input-group-append">
                                <CopyToClipboard onCopy={this.copy} text={this.state.key}>
                                <button class="btn btn-outline-secondary" type="button" disabled={this.state.key ? false : true}><Copy/>{" "}Copy</button>
                                </CopyToClipboard>
                                {" "}
                                {
                                    this.state.is_key_generted ? 
                                    (
                                        <button className="btn btn-danger">
                                            <RefreshCcw/> Regenerate
                                        </button>
                                    )
                                    :
                                    (
                                        <button className="btn btn-primary">
                                            <Settings/> Generate Key
                                        </button>
                                    )

                                }
                                
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="col mt-5 mb-5">
                            <h5 className="font-weight-bold color-grey">To Read API Documentation</h5>
                            <button className="btn btn-danger">Click Here</button>
                        </div>

                    </div>
                </div>
                <ToastContainer />
            </main>
        )
    }
}
