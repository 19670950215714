import React, { PureComponent } from "react";
import Server from "../../../routes/server";


export default class Certificate_Design_Guide extends PureComponent {

  componentDidMount(){
    Server.Session_check()
  }
  
  render() {
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4 shadow-sm  pl-3">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Certificate Design Guide</h3>
          </div>
        </div>

        <div className="row my-5">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <div className="header mt-md-5 text-center">
                  <div className="header-body">
                    <h5 className="header-title">Certificate Design Guide </h5>
                    <p>
                      {" "}
                      You have full flexibility to choose the Design you like
                      but we want you to make sure your design image has
                      required spaces for Certifidoc to place QR code and
                      Recipient names{" "}
                    </p>
                  </div>
                </div>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title"> CERTIFICATE DESIGN </h5>
                  </div>
                </div>
                <p>Sample Design</p>
                <div className="text-center">
                  <img
                    className="img-fluid rounded-lg"
                    width="75%"
                    src="/assets/img/certificate-guide/sample-cert.png"
                  ></img>
                </div>

                <h6 className="mt-3">
                  Please refer to following image for uploading your certificate
                  design.The design should have specific spaces to be left for
                  further processing and editing.
                </h6>

                <p>POINTS TO KEEP IN MIND WHILE UPLOADING :-</p>

                <ul>
                  <li>
                    <b>Leave space for the recipient name.</b>
                    <p>
                      (as you can see in the image the red box shows where the
                      recipient name will be, it totally depends on the
                      publisher where they want the recipient name i.e it can be
                      anywhere in the certificate but space must be left)
                    </p>
                  </li>
                  <li>
                    <b>
                      Leave space for the QR code to be placed for verification
                      and quick access.
                    </b>

                    <p>
                      (as you can see in the image the black box shows where the
                      recipient name will be.it totally depends on the publisher
                      where they want the QR code i.e it can be anywhere in the
                      certificate)
                    </p>
                  </li>
                </ul>
                <div className="text-center">
                  <img
                    className="img-fluid rounded-lg"
                    width="75%"
                    src="/assets/img/certificate-guide/spaces-marked.png"
                  ></img>
                </div>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title"> Certificate Dimensions</h5>
                  </div>
                </div>
                <h6 className="mt-3">
                  <b>Certificates usually have</b>
                </h6>
                <p>Aspect ratio of 1000 : 707</p>

                <ul>
                  <li>Good Quality in 2000(Width) by 1414(Height) px</li>
                  <li>High quality in 4420(Width) by 3125(Height) px</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
