import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
// import Server from "../../routes/server";
import { Eye, EyeOff } from "react-feather";
import axios from "axios";
import Recaptcha from "react-google-invisible-recaptcha";
import { server, SITE_KEY, logo } from "../../../env";

export default class Forgot extends PureComponent {
  state = {
    email_sent: false,
    show_password_icon: <Eye size={14} />,
    password_type: "password",
    reCaptcha: null,
    email: "",
    otp: "",
    password: "",
    btn_loading: false,
    spinner_loader: false,
    second: 0,
    alertClass: "d-none",
    alertMessage: "",
  };

  componentDidMount = () => {
    document.body.style.backgroundImage =
      "url(/assets/img/auth_page_background.png)";
  };

  show_Password = () => {
    const { password_type } = this.state;
    this.setState({
      show_password_icon:
        password_type === "password" ? <EyeOff size={14} /> : <Eye size={14} />,
      password_type: password_type === "password" ? "text" : "password",
    });
  };

  resend_otp = async () => {
    this.setState({ spinner_loader: true, second: 30 });
    await this.recaptcha.execute();
    const paramsss = new URLSearchParams();
    paramsss.append("email", this.state.email);
    paramsss.append("g-recaptcha-response", this.state.reCaptcha);
    axios
      .post(server + "/user/generate-otp", paramsss)
      .then((rsp) => {
        this.setState({ second: 30, spinner_loader: false });
        this.setState({
          alertClass: "form-group alert alert-success",
          alertMessage: "OTP has been sent. Please check your email.",
        });
        this.myInterval = setInterval(() => {
          if (this.state.second > 0) {
            this.setState(({ second }) => ({
              second: second - 1,
            }));
          } else {
            clearInterval(this.myInterval);
          }
        }, 1000);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({
            second: "0",
            spinner_loader: false,
            alertClass: "d-none",
            alertMessage: "",
          });
        }
      });
  };

  onTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = async (e) => {
    e.preventDefault();
    e.persist();
    await this.recaptcha.execute();
    this.setState({ btn_loading: true });
    const paramsss = new URLSearchParams();
    paramsss.append("email", this.state.email);
    paramsss.append("g-recaptcha-response", this.state.reCaptcha);
    axios
      .post(server + "/user/generate-otp", paramsss)
      .then((rsp) => {
        this.setState({
          email_sent: true,
          btn_loading: false,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({
            btn_loading: false,
            alertClass: "form-group alert alert-danger",
            alertMessage: error.response.data.response,
          });
          // this.setState({
          //   alertMessage:"Some error occured. Please try again...",
          //   alertClass:"alert alert-danger rounded-lg alert-dismissible",
          //   closeClass:"close pt--3",
          // })
        }
      });
  };

  auth_otp = async (e) => {
    e.preventDefault();
    await this.recaptcha.execute();
    this.setState({ btn_loading: true });
    const params = new URLSearchParams();
    params.append("user_email", this.state.email);
    params.append("user_otp", this.state.otp);
    params.append("g-recaptcha-response", this.state.reCaptcha);
    axios
      .post(server + "/user/auth-otp", params)
      .then((rsp) => {
        this.reset_password(rsp.data.user_token);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({
            btn_loading: false,
            alertMessage: "Invalid OTP",
            alertClass: "alert alert-danger rounded-lg alert-dismissible",
          });
        }
      });
  };
  reset_password = async (token) => {
    await this.recaptcha.execute();
    const params = new URLSearchParams();
    params.append("user_token", token);
    params.append("password", this.state.password);
    params.append("g-recaptcha-response", this.state.reCaptcha);
    axios
      .post(server + "/user/reset-password", params)
      .then((rsp) => {
        this.setState({
          alertClass: "form-group alert alert-success",
          alertMessage: "Your Password has been changed. Please wait...",
          btn_loading: false,
        });
        window.location.href = "/";
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ btn_loading: false });
        }
      });
  };
  onResolved = () => {
    this.setState({ reCaptcha: this.recaptcha.getResponse() });
  };

  render() {
    const {
      email_sent,
      show_password_icon,
      password_type,
      btn_loading,
      second,
      alertClass,
      alertMessage,
    } = this.state;
    return (
      <div
        style={{
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="wilyer-container"
      >
        <div className="wilyer-signin">
          <div className="card rounded-xl mt-4 shadow-sm">
            <div className="text-center p-5 pb-0">
              <img
                src={"assets/img/logo/" + logo}
                height="auto"
                width="40%"
                className="navbar-brand-img mx-auto"
                alt="Wilyer CertifiDoc Logo"
              />
              <div className="h4 pt-3">
                {email_sent
                  ? "Reset your account password"
                  : "Recover Password"}
              </div>
            </div>
            <div className="card-body p-5">
              {!email_sent ? (
                //   Forgot password
                <form onSubmit={this.submitHandler}>
                  <div className={alertClass} role="alert">
                    {alertMessage}
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control rounded-lg"
                      autoFocus={true}
                      required
                      onChange={this.onTextChange}
                    />
                  </div>
                  <div className="form-group pt-4">
                    <button
                      className="btn btn-primary form-control rounded-lg"
                      disabled={btn_loading ? true : false}
                    >
                      Continue &nbsp;
                      {btn_loading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        ""
                      )}
                    </button>
                    <br />
                    <p className="pt-3 pb-0 text-center">
                      <small>
                        Remember password?
                        <Link to="/login" className="ml-1 text-decoration-none">
                          Log In
                        </Link>
                      </small>
                      <span className="pl-2 pr-2">|</span>
                      <small>
                        Don't have an account?
                        <Link
                          to="/signup"
                          className="ml-1 text-decoration-none"
                        >
                          Sign Up
                        </Link>
                      </small>
                    </p>
                  </div>
                </form>
              ) : (
                //   Reset Password
                <form onSubmit={this.auth_otp}>
                  <div className={this.state.alertClass}>
                    {this.state.alertMessage}
                  </div>
                  <div className="form-group">
                    <label htmlFor="otp">OTP</label>
                    <input
                      type="number"
                      name="otp"
                      id="otp"
                      className="form-control rounded-lg"
                      autoFocus={true}
                      required
                      onChange={this.onTextChange}
                    />
                  </div>
                  <div className="form-group pt-4">
                    <label htmlFor="username">New Password</label>
                    <div className="input-group">
                      <input
                        type={password_type}
                        name="password"
                        id="password"
                        className="form-control password-field"
                        required
                        onChange={this.onTextChange}
                      />
                      <span
                        type="button"
                        className="input-group-text bg-default"
                        onClick={this.show_Password}
                      >
                        {show_password_icon}
                      </span>
                    </div>
                  </div>
                  <div className="form-group pt-4">
                    <button
                      className="btn btn-primary form-control rounded-lg"
                      disabled={btn_loading ? true : false}
                    >
                      Change Password &nbsp;
                      {btn_loading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        ""
                      )}
                    </button>
                    <br />
                    <p className="pt-3 pb-0 text-center">
                      <small>
                        Remember password?
                        <Link to="/login" className="ml-1 text-decoration-none">
                          Log In
                        </Link>
                      </small>
                      <span className="pl-2 pr-2">|</span>
                      <small>
                        Didn't get OTP?
                        {this.state.spinner_loader ? (
                          <div
                            className="spinner-border spinner-border-sm text-primary ml-2"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : this.state.second === 0 ? (
                          <Link to="#" onClick={this.resend_otp}>
                            {" "}
                            Resend Now
                          </Link>
                        ) : (
                          " Resend OTP in " + second + " seconds"
                        )}
                      </small>
                    </p>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        <Recaptcha
          ref={(ref) => (this.recaptcha = ref)}
          sitekey={SITE_KEY}
          onResolved={this.onResolved}
        />
      </div>
    );
  }
}
