import React, { PureComponent } from "react";
import Server from "../../../routes/server";

export default class FAQs extends PureComponent {
  componentDidMount(){
    Server.Session_check()
  }
  render() {
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4 shadow-sm  pl-3">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">FAQs</h3>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <div className="header mt-md-5 text-center">
                  <div className="header-body">
                    <h5 className="header-title"> Certifidoc FAQs </h5>
                    <p> Frequently asked questions by users </p>
                  </div>
                </div>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      {" "}
                      How to create a new event ?{" "}
                    </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  Visit the dashboard and click on a New event. or You can find
                  'New Event' button in Navigation Bar
                  <br></br>
                  Fill in the details and upload design of your certificate and
                  then save the event.
                </p>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      {" "}
                      How to edit a created event ?{" "}
                    </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  You can edit created events simply by visiting the{" "}
                  <b>your events</b> tab from and choose the event you want to
                  edit from the list.{" "}
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      {" "}
                      How to make certificates public ?
                    </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  There are 2 things to keep in mind
                  <ol>
                    <li>
                      when you upload new credentials they will only be visible
                      to public if credentials are published.
                    </li>
                    <li>
                      Second the Event itself should also be published to make
                      the published certificates public
                    </li>
                  </ol>
                </p>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      {" "}
                      What does Unpublished Event mean?
                    </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  Unpublished Event means the certificates and credentials will
                  not be visible to anybody even to the recipients.{" "}
                </p>
                <p>
                  <b>Note:</b>
                  You can change the privacy of the certificates by changing the
                  setting of the event.
                </p>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      {" "}
                      How to add QR code in certificates?{" "}
                    </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  You don't have to worry, certifidoc publishes each certificate
                  with QR code attached for quick verification. You will just
                  need to place the QR code in Certificate Design once and Each
                  Certificate will be published with QR Placed as you selected
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      {" "}
                      How to edit the QR code Design?{" "}
                    </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  The QR code can be edited, changes like QR code color, size
                  and alignment can be made in the certificate editor.{" "}
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      {" "}
                      How to add multiple credentials at one time?{" "}
                    </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  You can add multiple data entries or credentials using two
                  options :-
                </p>
                <ul>
                  <li>
                    By Uploading the excel or csv file containing the data in
                    the Add credential section. The sample Excel Sheet is
                    provided in the add new credentials page.
                  </li>
                  <li>
                    By manually typing the details of each person one by one in
                    the Add credential section.
                  </li>
                </ul>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      {" "}
                      How to share certificates on social media sites?(For
                      Recipients){" "}
                    </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  Various social media sharing buttons are available in the
                  certificate viewer section through which you can share your
                  certificates to different social media platforms with just a
                  few clicks.{" "}
                </p>
                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      How to import data entry from documents?
                    </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  Details of Certificate holders can be added with excel files
                  containing data. Just upload your excel file and you are good
                  to go.{" "}
                </p>

                <div className="header mt-md-5">
                  <div className="header-body">
                    <h5 className="header-title">
                      What should we do if we have trouble making Excel Sheet?
                    </h5>
                  </div>
                </div>
                <p>
                  {" "}
                  We know it can be hard to add data in specific format. Please
                  Contact Our Support Team to get help in porcessing of Excel
                  sheet for making it relevent to Certifidoc.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
