import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Server from "../../routes/server";
import axios from "axios";
import { ClassicSpinner } from "react-spinners-kit";
import Modal from "../../components/modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import { server, canva_api_key, environment } from "../../../env";

export default class Template extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      template_data: [],
      loaded: false,
      img_src: "https://homepages.cae.wisc.edu/~ece533/images/airplane.png",
      img_change: false,
      items: Array.from({ length: 12 }),
    };
  }

  componentDidMount() {
    Server.Session_check();
    this.getTemplate();
  }

  createFile = async (url, id) => {
    this.setState({ loaded: false });
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type: "image/jpeg",
    };
    let file = new File([data], "temp.jpg", metadata);
    this.setState({ template_img_path: file });
    this.upload_template_by_canva(file, id);
  };

  loadCanvaSDK = () => {
    ((document, url) => {
      var script = document.createElement("script");
      script.src = url;
      script.onload = () => {
        // API initialization
        (async () => {
          if (window.Canva && window.Canva.DesignButton) {
            const api = await window.Canva.DesignButton.initialize({
              apiKey: canva_api_key,
            });
            // Use "api" object or save for later
            api.createDesign({
              design: {
                type: "Certificate",
              },
              onDesignOpen: ({ designId }) => {
                // Triggered when editor finishes loading and opens a new design.
                // You can save designId for future use.
              },
              onDesignPublish: ({ exportUrl, designId }) => {
                this.createFile(exportUrl, designId);
                // Triggered when design is published to an image.
                // Save the image to your server as the exportUrl will expire shortly.
              },
              onDesignClose: () => {
                // Triggered when editor is closed.
              },
            });
          }
        })();
      };
      document.body.appendChild(script);
    })(document, "https://sdk.canva.com/designbutton/v2/api.js");
  };

  notify = (msg, toast_type) => {
    toast(msg, {
      type: toast_type,
    });
  };

  getTemplate = () => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(server + "/template/my-template", config)
      .then((rsp) => {
        this.setState({
          template_data: rsp.data.results,
          next_url: rsp.data.next,
          loaded: true,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };

  remove_template = (id, index) => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(server + "/template/delete/" + id, config)
      .then((rsp) => {
        const template_data = Object.assign([], this.state.template_data);
        template_data.splice(index, 1);
        this.setState({ template_data: template_data });
        this.notify("Template has been deleted.", "success");
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.notify(
            "You can't delete this template. Template is associated with events.",
            "error"
          );
        }
      });
  };

  paginationData = (url) => {
    if (environment == "PROD") {
      var url = url.replace("https", "http");
      var url = url.replace("http", "https");
    }
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(url, config)
      .then((rsp) => {
        let response = rsp.data.results;
        let comments = this.state.template_data;
        var i;
        let single_res;
        for (i = 0; i < response.length; i++) {
          single_res = rsp.data.results[i];
          comments.push(single_res);
        }

        this.setState({
          template_data: comments,
          next_url: rsp.data.next,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };

  setProfile = (event) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      $("#user_avtar").attr("src", e.target.result);
    };

    reader.readAsDataURL(event.target.files[0]);
    this.setState({
      template_img_path: event.target.files[0],
      img_change: true,
    });
    console.log(event.target.files[0]);
  };
  getProfile = () => {
    document.getElementById("input_file").click();
  };

  upload_template = (e) => {
    e.preventDefault();
    this.setState({ spinner: true });
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const params = new FormData();
    params.append("template_img", this.state.template_img_path);
    axios
      .post(server + "/template/create", params, config)
      .then((rsp) => {
        this.notify("Template has been uploaded.", "success");
        var myModal = document.getElementById("temp_upload").click();
        let response = {
          template_id: rsp.data.template_id,
          template_img: rsp.data.template_img,
          template_created_on: rsp.data.template_created_on,
          template_created_by: rsp.data.template_created_by,
        };
        const template_data = Object.assign([], this.state.template_data);
        template_data.unshift(response);
        this.setState({ template_data: template_data });
      })

      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ spinner: false });
        }
      });
  };
  upload_template_by_canva = (file, id) => {
    console.log(file);
    // e.preventDefault()
    // this.setState({spinner:true})
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const params = new FormData();
    params.append("template_img", file);
    params.append("design_id", id);
    axios
      .post(server + "/template/create", params, config)
      .then((rsp) => {
        console.log(rsp);
        this.notify("Template has been uploaded.", "success");
        let response = {
          template_id: rsp.data.template_id,
          template_img: rsp.data.template_img,
          template_created_on: rsp.data.template_created_on,
          template_created_by: rsp.data.template_created_by,
        };
        const template_data = Object.assign([], this.state.template_data);
        template_data.unshift(response);
        this.setState({ template_data: template_data, loaded: true });
      })

      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ spinner: false, loaded: true });
        }
      });
  };
  render() {
    const { template_data, img_src } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4 shadow-sm  pl-3">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">FAQs</h3>
            <div className="btn-toolbar mb-0 mb-md-0">
              <button
                className="btn btn-primary btn-sm rounded-pill pl-3 pr-3 mr-1"
                onClick={this.loadCanvaSDK}
              >
                Create New Template{" "}
              </button>
              <button
                className="btn btn-primary btn-sm rounded-pill pl-3 pr-3"
                data-toggle="modal"
                data-target="#upload_template"
              >
                Upload Template{" "}
              </button>
            </div>
          </div>
        </div>
        {this.state.loaded ? (
          <div className="card mt-5 p-3">
            <div className="card-body">
              <div className="row g-3 my-5">
                {template_data.length < 1 ? (
                  <p className="text-center text-muted">No template found</p>
                ) : (
                  template_data.map((data, index) => (
                    <div className="col-auto d-flex" key={index}>
                      <div className="card lift mt-4 shadow-sm border">
                        <img
                          className="img-fluid"
                          style={{ height: "230px" }}
                          src={data.template_img}
                          alt="Card image cap"
                          data-toggle="modal"
                          data-target="#modal_view"
                          onClick={() => {
                            this.setState({
                              img_src: data.template_img,
                            });
                          }}
                        />
                        <div className="card-body row">
                          <div className="mt-3">
                            <div className="row">
                              <div className="col-auto">
                                <button
                                  type="button"
                                  className="btn btn-success btn-sm"
                                  data-toggle="modal"
                                  data-target="#modal_view"
                                  onClick={() => {
                                    this.setState({
                                      img_src: data.template_img,
                                    });
                                  }}
                                >
                                  View
                                </button>
                              </div>
                              <div className="col-auto">
                                <button
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                  onClick={this.remove_template.bind(
                                    this,
                                    data.template_id,
                                    index
                                  )}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
                {this.state.next_url ? (
                  <div className="col-12 text-center mt-5">
                    <button
                      className="btn btn-white btn-sm rounded-pill border px-3"
                      onClick={this.paginationData.bind(
                        this,
                        this.state.next_url
                      )}
                    >
                      Show more{" "}
                      {this.state.show_more ? (
                        <ClassicSpinner
                          size={13}
                          className="d-inline-block"
                          color="black"
                          loading={true}
                        />
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            {this.state.items.map((i, index) => (
              <div className="col-md-3" key={index}>
                <div className="card">
                  <div className="wrapperr">
                    <div className="comment br animate w80"></div>
                    <div className="comment br animate"></div>
                    <div className="comment br animate"></div>
                    <div className="cirle-wrap">
                      <div className="profilePic animate din"></div>
                      <div className="profilePic animate din"></div>
                      <div className="profilePic animate din"></div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <Modal
          key="modal_view"
          id="modal_view"
          title=""
          close_id="view_close"
          className="modal-xl"
        >
          <div className="modal-body text-center p-4 mb-3">
            <img style={{ width: "100%" }} src={img_src} alt="" />
          </div>
        </Modal>
        {/* Template Upload Modal */}
        <Modal
          key="upload_template"
          id="upload_template"
          close_id="temp_upload"
          title="Upload Template"
        >
          <div class="alert alert-warning text-center" role="alert">
            <small className="text-dark text-center">
              {" "}
              The Certificate Design you upload should satisfy some
              requirements.{" "}
            </small>
            <small className="text-dark text-center">
              {" "}
              Please refer to{" "}
              <Link
                rel="noreferrer noopener"
                target="_blank"
                to={"/cert-design-guide"}
              >
                Certificate Design Guide
              </Link>
              &nbsp; or{" "}
              <Link
                rel="noreferrer noopener"
                target="_blank"
                download="certifidoc-sample-cert.png"
                to={require("../../img/sample-cert.png")}
              >
                Download a Sample Certificate Design
              </Link>{" "}
              to Test Certifidoc.
            </small>
          </div>
          <form onSubmit={this.upload_template}>
            <div className="modal-body p-3">
              <div className="form-group">
                <div>
                  <img
                    id="user_avtar"
                    src="../../../../assets/img/certificate_template/no_template.png"
                    alt="..."
                    className="avatar-img"
                    style={{ width: 450, height: "auto" }}
                  />
                </div>
                {"\u00A0"}
                {"\u00A0"}
                <button
                  className="btn btn-primary btn-sm mt-2"
                  type="button"
                  onClick={this.getProfile}
                >
                  Choose Template
                </button>
                <input
                  type="file"
                  accept="image/*"
                  onChange={this.setProfile}
                  className="d-none"
                  id="input_file"
                />
              </div>
            </div>
            <div className="modal-footer border-0 pb-4">
              <button
                type="submit"
                className="btn btn-primary btn-sm rounded-pill pl-3 pr-3"
                disabled={this.state.img_change ? false : true}
              >
                Upload Template{" "}
                {this.state.spinner ? (
                  <ClassicSpinner
                    size={13}
                    className="d-inline-block"
                    color="#fff"
                    loading={true}
                  />
                ) : (
                  ""
                )}
              </button>
            </div>
          </form>
        </Modal>
        <ToastContainer />
      </main>
    );
  }
}
