import React, { PureComponent } from "react";
import "flatpickr/dist/themes/material_green.css";
import axios from "axios";
import Server from "../../routes/server";

import Flatpickr from "react-flatpickr";
import { server } from "../../../env";

export default class AddEvent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      start_date: null,
      end_date: null,
      public_name: "",
      private_name: "",
      type: "",
      expires: false,
      description: "",
      website: "",
      public_profile: false,
      share_creds: false,
      spinner: false,
      clicked: null,
      isOther: false,
    };
  }
  componentDidMount() {
    Server.Session_check();
  }

  onTextChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  oncheckboxchage = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  setSubmitButton = (name) => {
    this.setState({ setSubmitButton: name });
  };

  submitHandler = (e) => {
    e.preventDefault();
    e.persist();
    this.setState({ spinner: true });
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const params = new URLSearchParams();
    params.append("public_name", this.state.public_name);
    params.append("private_name", this.state.private_name);
    params.append("certificate_type", this.state.type);
    params.append("is_expires", this.state.expires);
    params.append("website", this.state.website);
    params.append("description", this.state.description);
    params.append("is_profile_public", this.state.public_profile);
    params.append("is_cred_shareable", this.state.share_creds);
    params.append("extraFields", e.target.extraFields.value);

    if (this.state.start_date !== null) {
      params.append("start_date", this.state.start_date);
    }
    if (this.state.end_date !== null) {
      params.append("end_date", this.state.end_date);
    }

    axios
      .post(server + "/event/create", params, config)
      .then((rsp) => {
        this.setState({ spinner: false });
        if (this.state.setSubmitButton === "save") {
          this.props.history.push("/events");
        } else {
          this.props.history.push("/certificate-editor/" + rsp.data.event_id);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ spinner: false });
        }
      });
  };
  convert_date(name, date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    var converted_date = year + "-" + month + "-" + day;
    this.setState({ [name]: converted_date });
  }

  onTypeChange = (e) => {
    if (e.target.value === "Other") {
      this.setState({ isOther: true });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        isOther: false,
      });
    }
  };

  render() {
    const { start_date } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4 shadow-sm pl-3">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Add New Event</h3>
          </div>
        </div>
        <div className="card my-5">
          <div className="card-body p-3 border-0">
            <div></div>
            <form onSubmit={this.submitHandler}>
              <div className="row p-3 g-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="public_name">
                      Display Name <b className="text-danger">*</b>
                    </label>
                    <br></br>
                    <small className="form-text text-muted">
                      {" "}
                      This name will be visible to public on Certificates and
                      Emails
                    </small>
                    <input
                      type="text"
                      name="public_name"
                      id="public_name"
                      className="form-control rounded-lg mt-2 p-2"
                      autoFocus={true}
                      required
                      placeholder="Display Name"
                      onChange={this.onTextChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="private_name">
                      Event Name <b className="text-danger">*</b>
                    </label>
                    <br></br>
                    <small className="form-text text-muted">
                      This name will only be visible on your Dashboard
                    </small>
                    <input
                      type="text"
                      name="private_name"
                      id="private_name"
                      className="form-control rounded-lg mt-2 p-2"
                      required
                      placeholder="Event Name"
                      onChange={this.onTextChange}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="type">
                      Certificate Type <b className="text-danger">*</b>
                    </label>
                    <select
                      className="form-control mt-2 p-2"
                      id="type"
                      name="type"
                      onChange={this.onTypeChange}
                      required
                    >
                      <option value="">Select Type</option>
                      <option value="Internship">Internship</option>
                      <option value="Participation">Participation</option>
                      <option value="Excellence">Excellence</option>
                      <option value="Achievement">Achievement</option>
                      <option value="Qualification">Qualification</option>
                      <option value="Appreciation">Appreciation</option>
                      <option value="Performance">Performance</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                {this.state.isOther ? (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="type1">
                        Please specify certificate type{" "}
                        <b className="text-danger">*</b>
                      </label>
                      <br></br>
                      <input
                        type="text"
                        name="type"
                        id="type1"
                        className="form-control rounded-lg mt-2 p-2"
                        required
                        placeholder="Certificate type"
                        onChange={this.onTextChange}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="type">
                      Extra Fields <b className="text-danger">*</b>
                    </label>
                    <input
                      type="number"
                      name="extraFields"
                      id="extraFields"
                      className="form-control rounded-lg mt-2 p-2"
                      defaultValue={0}
                      min={0}
                      max={7}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row pb-3 mt-4">
                <div className="col-md-12">
                  <hr></hr>
                  <h7 class="text-uppercase text-muted font-weight-bold">
                    {" "}
                    About Event{" "}
                  </h7>
                </div>
              </div>
              <div className="row p-3">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="start_data">Start Date</label>
                    <Flatpickr
                      defaultValue="Select Date"
                      className="form-control rounded-lg mt-2 p-2"
                      onChange={(start_date) => {
                        this.convert_date("start_date", start_date[0]);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="end_data">End Date</label>
                    <Flatpickr
                      defaultValue="Select Date"
                      className="form-control rounded-lg mt-2 p-2"
                      onChange={(end_date) => {
                        this.convert_date("end_date", end_date[0]);
                      }}
                      options={{ minDate: start_date }}
                    />
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="form-group">
                  <label htmlFor="expires">Expires (Choose if Certificate Expires)</label>
                  <br></br>
                      <label className="switch mt-2">
                        <input type="checkbox"
                        name="expires"
                        id="expires"
                        className="form-control"
                        onChange={this.oncheckboxchage}
                        />
                        <span className="slider round"></span>
                      </label>
                  </div>
                </div> */}
              </div>
              <div className="row p-3">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="description">Event Description</label>
                    <br></br>
                    <small className="form-text text-muted">
                      {" "}
                      This will be shown to others publicly, so choose it
                      carefully.
                    </small>
                    <textarea
                      className="form-control rounded-lg h-75 mt-1"
                      placeholder="Description"
                      id="description"
                      name="description"
                      onChange={this.onTextChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row p-3">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="website">
                      Your Company's Website Domain
                    </label>
                    <br></br>
                    <small className="form-text text-muted">
                      {" "}
                      Visitor will be redirected to this when they see your
                      Website.
                    </small>
                    <input
                      type="url"
                      name="website"
                      id="website"
                      className="form-control rounded-lg mt-2 p-2"
                      placeholder="https://www.example.com"
                      onChange={this.onTextChange}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="row p-3">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="public_profile">Public Profile</label>
                    <br></br>
                    <small className="text-muted">Making your profile public means that anyone on the web can see your event Details and Other participants</small>
                    <br></br>
                    <label className="switch mt-2">
                        <input type="checkbox"
                        name="public_profile"
                        id="public_profile"
                        className="form-control"
                        onChange={this.oncheckboxchage}
                        />
                        <span className="slider round"></span>
                      </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="share_creds">Allow for Credentials to be shared</label>
                    <br></br>
                    <small className="text-muted">If you are available for hire outside of the current situation, you can encourage others to hire you.</small>
                    <br></br>
                    <label className="switch mt-2">
                        <input type="checkbox"
                        name="share_creds"
                        id="share_creds"
                        className="form-control"
                        onChange={this.oncheckboxchage}
                        />
                        <span className="slider round"></span>
                      </label>
                  </div>
                </div>
              </div> */}
              <div className="form-group mt-5 mb-0">
                <button
                  class="btn btn-primary lift"
                  onClick={this.setSubmitButton.bind(this, "proceed")}
                  type="submit"
                  disabled={this.state.spinner ? true : false}
                >
                  Proceed &nbsp;
                  {this.state.spinner ? (
                    this.state.setSubmitButton === "proceed" ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </button>
                &nbsp;&nbsp;
                <button
                  class="btn btn-success lift"
                  onClick={this.setSubmitButton.bind(this, "save")}
                  type="submit"
                  disabled={this.state.spinner ? true : false}
                >
                  Save as draft &nbsp;
                  {this.state.spinner ? (
                    this.state.setSubmitButton === "save" ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    );
  }
}
