import React, { PureComponent } from "react";
import { Facebook, Twitter, Instagram, Linkedin } from "react-feather";
import Server from "../../../routes/server";

export default class Contact extends PureComponent {
  componentDidMount() {
    Server.Session_check();
  }
  render() {
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4 shadow-sm  pl-3">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Contact Us</h3>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-6">
            <div class="card p-3">
              <div class="text-center mt-4">
                <img
                  src="https://wilyer.com/assets/img/logos/logo.png"
                  alt="Wilyer Logo"
                />
                <span></span>
              </div>
              <div class="card-body">
                <div class="row align-items-center my-3 border-bottom">
                  <div class="col">
                    <h4 class="card-header-title"> Contact Details </h4>
                  </div>
                </div>
                <strong>Company Name:</strong>
                <span>&nbsp;&nbsp;Wilyer</span>
                <br />
                <strong>Service Name:</strong>
                <span>&nbsp;&nbsp;CertifiDoc</span>
                <br />
                <strong>Contact Number:</strong>
                <span>
                  &nbsp;&nbsp;
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none"
                    href="https://api.whatsapp.com/send?phone=918800581956"
                  >
                    {" "}
                    +918800581956
                  </a>{" "}
                  ,{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-decoration-none"
                    href="https://api.whatsapp.com/send?phone=918595119451"
                  >
                    {" "}
                    +918595119451
                  </a>
                </span>
                <br />
                <strong>Email: </strong>
                <span>
                  &nbsp;&nbsp;
                  <a
                    rel="noopener noreferrer"
                    href="mailto:care@wilyer.com"
                    className="text-decoration-none"
                  >
                    {" "}
                    care@wilyer.com
                  </a>
                  ,&nbsp;
                  <a
                    rel="noopener noreferrer"
                    href="mailto:wilyerofficial@gmail.com"
                    className="text-decoration-none"
                  >
                    {" "}
                    wilyerofficial@gmail.com
                  </a>
                </span>
                <br />
                <strong>Address:</strong>&nbsp;&nbsp;
                <span>
                  V-11, Mapsko Casabella, Sector 82, Gurugram 122004, Haryana
                </span>
              </div>
              <div className="card-footer text-center bg-transparent pt-3">
                <a
                  target="_blank"
                  href="https://www.facebook.com/wilyerofficial/"
                  rel="noopener noreferrer"
                  className="btn btn-sm btn-rounded btn-primary rounded-circle"
                >
                  <Facebook />
                </a>{" "}
                <a
                  target="_blank"
                  href="https://www.instagram.com/wilyerofficial/"
                  rel="noopener noreferrer"
                  className="btn btn-sm btn-rounded btn-danger rounded-circle"
                >
                  <Instagram />
                </a>{" "}
                <a
                  target="_blank"
                  href="https://twitter.com/wilyerofficial/"
                  rel="noopener noreferrer"
                  className="btn btn-sm btn-rounded btn-info rounded-circle"
                >
                  <Twitter />
                </a>{" "}
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/wilyer-tech"
                  rel="noopener noreferrer"
                  className="btn btn-sm btn-rounded btn-primary rounded-circle"
                >
                  <Linkedin />
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
