import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Eye, Edit2, Plus, Trash2, Clock, Upload } from "react-feather";
import { ClassicSpinner } from "react-spinners-kit";
import axios from "axios";
import Server from "../../routes/server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { server, environment } from "../../../env";

export default class Events extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      event_details: [],
      show_more: false,
      loading: true,
      items: Array.from({ length: 6 }),
    };
  }
  componentDidMount() {
    Server.Session_check();
    this.getEvents();
  }
  notify = (msg, toast_type) => {
    toast(msg, {
      type: toast_type,
    });
  };

  getEvents = () => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(server + "/event/my-events", config)
      .then((rsp) => {
        this.setState({
          event_details: rsp.data.results,
          next_url: rsp.data.next,
          loading: false,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };

  // Posted time function
  timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return interval + "years ago";
    }
    if (interval === 1) {
      return interval + "year ago";
    }

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " months ago";
    }
    if (interval === 1) {
      return interval + " month ago";
    }

    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " days ago";
    }
    if (interval === 1) {
      return interval + " day ago";
    }

    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " hours ago";
    }
    if (interval === 1) {
      return interval + " hour ago";
    }

    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutes ago";
    }
    if (interval === 1) {
      return interval + " minute ago";
    }

    return Math.floor(seconds) + "sec ago";
  }

  paginationData = (url) => {
    if (environment === "PROD") {
      url = url.replace("https", "http");
      url = url.replace("http", "https");
    }
    this.setState({ show_more: true });
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(url, config)
      .then((rsp) => {
        let response = rsp.data.results;
        let event_details = this.state.event_details;
        var i;
        let single_res;
        for (i = 0; i < response.length; i++) {
          single_res = rsp.data.results[i];
          event_details.push(single_res);
        }

        this.setState({
          event_details: event_details,
          next_url: rsp.data.next,
          show_more: false,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ show_more: false });
        }
      });
  };
  upload_template = (id) => {
    this.props.history.push("/certificate-editor/" + id);
  };

  handleDelete = (index, id, e) => {
    e.preventDefault();
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(server + "/event/delete/" + id, config)
      .then((rsp) => {
        const event_details = Object.assign([], this.state.event_details);
        event_details.splice(index, 1);
        this.setState({ event_details: event_details });
        this.notify("Event has been deleted.", "success");
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.notify(error.response.data.response, "error");
        }
      });
  };

  render() {
    const { event_details } = this.state;
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4 shadow-sm pl-3">
          <div className="d-flex row justify-content-between p-3">
            <div className="col-6">
              <h3 className="card-title h5 mb-0 mt-2">Your Events</h3>
            </div>
            <div className="col-6 text-right">
              <Link
                to="/add-event"
                className="btn btn-primary btn-sm px-3 add_event"
              >
                <Plus />
                Add Event
              </Link>
            </div>
          </div>
        </div>

        <div className="row my-5">
          {this.state.loading ? (
            this.state.items.map((i, index) => (
              <div className="col-md-4" key={index}>
                <div className="card p-4">
                  <div className="wrapperr">
                    <div className="comment br animate w80"></div>
                    <div className="comment br animate"></div>
                    <div className="comment br animate"></div>
                    <div className="cirle-wrap">
                      <div className="profilePic animate din"></div>
                      <div className="profilePic animate din"></div>
                      <div className="profilePic animate din"></div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : event_details.length > 0 ? (
            event_details.map((data, index) => (
              <div className="col-md-4" key={index}>
                <div className="card lift card-fill">
                  <Link to={"event-details/" + data.event_id}>
                    {data.active ? (
                      <img
                        className="card-img-top"
                        style={{ height: "300px" }}
                        src={data.event_template.template_img.template_img}
                        alt=""
                      />
                    ) : (
                      <img
                        className="card-img-top"
                        style={{ height: "300px" }}
                        src="../../../../assets/img/certificate_template/no_template.png"
                        alt=""
                      />
                    )}
                  </Link>
                  <div className="card-body">
                    <h6 className="card-title time-since d-inline-block">
                      {" "}
                      <Link
                        to={"event-details/" + data.event_id}
                        className="name"
                      >
                        {" "}
                        {data.private_name}{" "}
                      </Link>
                    </h6>
                    <p className="card-text small text-muted">
                      {" "}
                      <Clock />{" "}
                      <time dateTime="2018-05-24">
                        {this.timeSince(new Date(data.event_created_on))}
                      </time>
                      {data.publish ? (
                        <div className="d-inline-block">
                          <span class="badge badge-soft-success rounded-pill p-2 ml-2">
                            Published
                          </span>{" "}
                        </div>
                      ) : (
                        <div className="d-inline-block">
                          <span class="badge badge-soft-danger rounded-pill p-2 ml-2">
                            Not Published
                          </span>{" "}
                        </div>
                      )}
                    </p>
                    <hr className="text-muted"></hr>

                    <div className="row align-items-center justify-content-between">
                      <div className="col-auto">
                        <Link
                          className="btn btn-primary btn-sm lift rounded"
                          to={"event-details/" + data.event_id}
                        >
                          <Eye /> View
                        </Link>
                        &nbsp;
                        {data.active ? (
                          <Link
                            className="btn btn-warning btn-sm lift rounded"
                            to={"certificate-editor/" + data.event_id}
                          >
                            <Edit2 /> Edit
                          </Link>
                        ) : (
                          <Link
                            className="btn btn-warning btn-sm lift rounded"
                            to={"certificate-editor/" + data.event_id}
                          >
                            <Upload /> Upload
                          </Link>
                        )}{" "}
                        <button
                          className="btn btn-danger btn-sm lift rounded"
                          onClick={this.handleDelete.bind(
                            this,
                            index,
                            data.event_id
                          )}
                        >
                          <Trash2 /> Delete
                        </button>
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className=" mt-5 p-5">
              <p className="text-muted text-center">No Events found</p>
            </div>
          )}
          {this.state.next_url ? (
            <div className="col-12 text-center mt-5">
              <button
                className="btn btn-white btn-sm rounded-pill border px-3"
                onClick={this.paginationData.bind(this, this.state.next_url)}
              >
                Show more{" "}
                {this.state.show_more ? (
                  <ClassicSpinner
                    size={13}
                    className="d-inline-block"
                    color={
                      localStorage.getItem("theme-mode") === "dark"
                        ? "#b5b5b7"
                        : "#686769"
                    }
                    loading={true}
                  />
                ) : (
                  ""
                )}
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        <ToastContainer />
      </main>
    );
  }
}
