import React, { Fragment } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Topbar from "./components/header/topbar";
import Sidebar from "./components/header/sidebar";
import AuthRouter from "./components/routes/auth";
import DashboardRouter from "./components/routes/dashboard";
import Certificate from "./components/source/certificate";
import Temp from "./components/source/temp";
import Not_Found from "./components/source/404";
import isUUID from 'validator/lib/isUUID';
function App() {
  if (Date.parse(new Date()) - Date.parse(localStorage.timestamp) > 8 * 60 * 60 * 1000) {
    localStorage.removeItem("footprint");
    localStorage.removeItem("timestamp");
  }
  let login = localStorage.getItem("footprint") === null ? false : true;
  var url = window.location.href;
  var target = url.split('/');
  // login = false;
  console.log(target)
  return (
    <Fragment>
      <BrowserRouter>
        {
          target[target.length - 2] === "certificate" ?
          (
            <Fragment>
              <Switch>
                <Route path="/certificate/:credential_id" component={Certificate} />
              </Switch>
            </Fragment>
          ) 
          :
          (
            isUUID(target[3]) ? 
            (
              <Fragment>
                <Switch>
                  <Route path="/:credential_id" component={Temp} />
                </Switch>
              </Fragment>
            )
            :
            (
            target[target.length - 1] === "page-not-found" ?
            (
              <Fragment>
                <Switch>
                  <Route path="/page-not-found" component={Not_Found} />
                </Switch>
              </Fragment>
            )
            :
            login ?
            (
              <Fragment>
                <Topbar />
                <div className="container-fluid">
                  <div className="row">
                    <Sidebar />
                    <Switch>
                      <Route path="/" component={DashboardRouter} />
                    </Switch>
                  </div>
                </div>
              </Fragment>
            ) 
            : 
            (
              <Fragment>
                <Switch>
                  <Route path="/" component={AuthRouter} />
                </Switch>
              </Fragment>
            )
            )
          )
        }
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
