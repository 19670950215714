import React, { PureComponent } from "react";
import { ClassicSpinner } from "react-spinners-kit";
import axios from "axios";
import Server from "../../routes/server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Eye, EyeOff } from "react-feather";
import { server } from "../../../env";

export default class Settings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      old_password_icon: <Eye size={14} />,
      new_password_icon: <Eye size={14} />,
      cnf_password_icon: <Eye size={14} />,
      old_password_type: "password",
      new_password_type: "password",
      cnf_password_type: "password",
      name: null,
      org_name: null,
      btn_loading: false,
      pass_btn_loading: false,
      new_password2: "",
      new_password: "",
      old_password: "",
    };
  }

  componentDidMount() {
    Server.Session_check();
    this.getData();
  }

  getData = () => {
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    axios
      .get(server + "/user/user-details", config)
      .then((rsp) => {
        this.setState({
          name: rsp.data.user_fullname,
          org_name: rsp.data.org_name,
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        }
      });
  };

  onchange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  notify = (msg, toast_type) => {
    toast(msg, {
      type: toast_type,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ btn_loading: true });
    const token = localStorage.getItem("footprint");
    const config = {
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const params = new URLSearchParams();
    params.append("user_fullname", this.state.name);
    params.append("org_name", this.state.org_name);
    axios
      .post(server + "/user/update-details", params, config)
      .then((rsp) => {
        this.setState({
          btn_loading: false,
          name: rsp.data.user_fullname,
          org_name: rsp.data.org_name,
        });
        this.notify("Account details have been updated.", "success");
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.setState({ btn_loading: false });
          this.notify("Some error occured. Please try again.", "error");
        }
      });
  };

  changePassword = (e) => {
    e.preventDefault();
    if (this.state.new_password === this.state.new_password2) {
      this.setState({ pass_btn_loading: true });
      const token = localStorage.getItem("footprint");
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };
      const paramss = new URLSearchParams();
      paramss.append("old_password", this.state.old_password);
      paramss.append("new_password", this.state.new_password);
      paramss.append("new_password2", this.state.new_password2);
      axios
        .post(server + "/user/change-password/", paramss, config)
        .then((rsp) => {
          this.setState({
            pass_btn_loading: false,
            old_password: "",
            new_password: "",
            new_password2: "",
          });
          this.notify(rsp.data.response, "success");
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            this.setState({
              pass_btn_loading: false,
              old_password: "",
              new_password: "",
              new_password2: "",
            });
            this.notify(error.response.data.response, "error");
          }
        });
    } else {
      this.notify("New Password and Confirm Password didn't match", "error");
    }
  };

  show_Password = (type) => {
    const { old_password_type, new_password_type, cnf_password_type } =
      this.state;
    if (type === "old_password") {
      this.setState({
        old_password_icon:
          old_password_type === "password" ? (
            <EyeOff size={14} />
          ) : (
            <Eye size={14} />
          ),
        old_password_type:
          old_password_type === "password" ? "text" : "password",
      });
    } else if (type === "new_password") {
      this.setState({
        new_password_icon:
          new_password_type === "password" ? (
            <EyeOff size={14} />
          ) : (
            <Eye size={14} />
          ),
        new_password_type:
          new_password_type === "password" ? "text" : "password",
      });
    } else if (type === "cnf_password") {
      this.setState({
        cnf_password_icon:
          cnf_password_type === "password" ? (
            <EyeOff size={14} />
          ) : (
            <Eye size={14} />
          ),
        cnf_password_type:
          cnf_password_type === "password" ? "text" : "password",
      });
    }
  };
  render() {
    return (
      <main className="col-md-9 ml-sm-auto col-lg-10 px-md-3">
        <div className="card mt-4 shadow-sm  pl-3">
          <div className="d-flex justify-content-between p-3">
            <h3 className="card-title h5 mb-0 mt-1">Account Settings</h3>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-md-6">
            <div class="card p-3">
              <div className="card-header bg-transparent">
                <h6 className="card-title">Account Details</h6>
              </div>
              <form>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={this.state.name}
                      onChange={this.onchange}
                      className="form-control rounded-pill"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="org_name">Organisation Name</label>
                    <input
                      type="text"
                      name="org_name"
                      id="org_name"
                      value={this.state.org_name}
                      onChange={this.onchange}
                      className="form-control rounded-pill"
                    />
                  </div>
                  <div className="form-group text-right mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm rounded-pill px-3"
                      onClick={this.handleSubmit}
                    >
                      Update{" "}
                      {this.state.btn_loading ? (
                        <ClassicSpinner
                          size={13}
                          className="d-inline-block"
                          color="#fff"
                          loading={true}
                        />
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div class="card p-3">
              <div className="card-header bg-transparent">
                <h6 className="card-title">Security Settings</h6>
              </div>
              <form onSubmit={this.changePassword}>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="old_password">Old Password</label>
                    <div className="input-group">
                      <input
                        type={this.state.old_password_type}
                        name="old_password"
                        id="old_password"
                        onChange={this.onchange}
                        value={this.state.old_password}
                        required
                        className="form-control rounded-pill"
                      />
                      <span
                        type="button"
                        name="old_password"
                        className="input-group-text bg-default"
                        onClick={this.show_Password.bind(this, "old_password")}
                      >
                        {this.state.old_password_icon}
                      </span>
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="new_password">New Password</label>
                    <div className="input-group">
                      <input
                        type={this.state.new_password_type}
                        name="new_password"
                        id="new_password"
                        onChange={this.onchange}
                        value={this.state.new_password}
                        required
                        className="form-control rounded-pill"
                      />
                      <span
                        type="button"
                        name="new_password"
                        className="input-group-text bg-default"
                        onClick={this.show_Password.bind(this, "new_password")}
                      >
                        {this.state.new_password_icon}
                      </span>
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <label htmlFor="new_password2">Confirm Password</label>
                    <div className="input-group">
                      <input
                        type={this.state.cnf_password_type}
                        name="new_password2"
                        id="new_password2"
                        onChange={this.onchange}
                        value={this.state.new_password2}
                        required
                        className="form-control rounded-pill"
                      />
                      <span
                        type="button"
                        name="cnf_password"
                        className="input-group-text bg-default"
                        onClick={this.show_Password.bind(this, "cnf_password")}
                      >
                        {this.state.cnf_password_icon}
                      </span>
                    </div>
                  </div>
                  <div className="form-group text-right mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm rounded-pill px-3"
                    >
                      Update Password{" "}
                      {this.state.pass_btn_loading ? (
                        <ClassicSpinner
                          size={13}
                          className="d-inline-block"
                          color="#fff"
                          loading={true}
                        />
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </main>
    );
  }
}
